import { MenuItem, TextField } from '@mui/material';
import React from 'react';
import { getValueFromBits, getValuesFromBits } from '../../../../../../../redux/CUParameters/api/util/parameterUtil';
import { ParameterEditControlProps } from './ParameterEditControl';

export default function ParameterEditControlBitMultipleSelection(
  props: ParameterEditControlProps
) {
  const { parameter, onChangeValue, value, polyglot } = props;
  if (!parameter.bits) return null;
  const id = `select-multiple-parameter-${parameter.name}`;
  const bits = parameter.bits;
  const handleChange = (event: { target: { value: string | string[] } }) => {
    // On autofill we get a stringified value.
    const ids =
      typeof event.target.value === 'string'
        ? event.target.value.split(',')
        : event.target.value;

    const values = bits.filter((option) => ids.includes(option.id));
    onChangeValue(getValueFromBits(values));
  };
  const values = getValuesFromBits(bits, value).map((option) => option.id);
  return (
    <TextField
      id={id}
      SelectProps={{ multiple: true } as any}
      inputProps={{ 'data-testid': id }}
      select
      label={polyglot.t('device.parameters.values')}
      value={values}
      onChange={handleChange}
      fullWidth
    >
      {bits.map((option) => (
        <MenuItem key={option.id} value={option.id}>
          {`+${2 ** parseInt(option.id)}: ${option.text}`}
        </MenuItem>
      ))}
    </TextField>
  );
}
