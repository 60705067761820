import { Box, useTheme } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import { Link, styled, Typography } from '@mui/material';
import React from 'react';
import { Link as LinkRouter } from 'react-router-dom';
import { getPolyglot } from '../../../i18n';

const LinkStyled: typeof Link = styled(Link)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    fontSize: theme.typography.body2.fontSize,
  },
}));
export interface AuthLayoutFooterProps {
  urlAboutUs?: string;
}
function AuthLayoutFooter(props: AuthLayoutFooterProps) {
  const { urlAboutUs = 'http://stoerk-tronic.com/' } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const polyglot = getPolyglot();
  return (
    <Box display={'flex'} flexWrap={'wrap'}>
      <LinkStyled to="/terms-and-conditions" component={LinkRouter}>
        {polyglot.t('footer.terms_and_conditions')}
      </LinkStyled>
      <Typography color={theme.palette.primary.main}>{'  ·  '}</Typography>
      <LinkStyled to="/privacy" component={LinkRouter}>
        {polyglot.t('footer.privacy_policy')}
      </LinkStyled>
      <Typography color={theme.palette.primary.main}>{'  ·  '}</Typography>
      <LinkStyled to="/cookies" component={LinkRouter}>
        {polyglot.t('footer.cookie_policy')}
      </LinkStyled>
      <Typography color={theme.palette.primary.main}>{'  ·  '}</Typography>
      <LinkStyled to="/impressum" component={LinkRouter}>
        {polyglot.t('footer.impressum')}
      </LinkStyled>
      <Typography color={theme.palette.primary.main}>{'  ·  '}</Typography>
      <LinkStyled href={urlAboutUs}>{polyglot.t('footer.about_us')}</LinkStyled>
    </Box>
  );
}

export default AuthLayoutFooter;
