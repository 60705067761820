import { useEffect, useMemo } from 'react';
import { DEFAULT_EVENT_ON } from '../api/util/constants';
import { getMainSensor } from '../api/util/sensor/getMainSensor';
import { DigitalChannelEventMixed } from '../../../redux/monitoring/api/monitoring.model';
import { filterAlarmsFromEvents } from '../../../redux/monitoring/api/util/filterAlarmsFromEvents';
import { getDigitalEventValue } from '../../../redux/monitoring/api/util/mapDigitalEvent';
import { useCUTranslate } from '../../CUTranslation/hook/useCUTranslate';
import { useAppSelector } from '../../store.model';
import { getActionExtendedSelectorById } from '../selectors/actions';
import { getSetPointsSelectorById } from '../selectors/setPoint';
import { useControlUnitById } from './useControlUnitById';
import { useCurrentEventsControlUnitById } from './useCurrentEventsControlUnitById';
import { useCurrentSensorDataControlUnitsByDeviceId } from './useCurrentSensorDataControlUnitsByDeviceId';
import { getStatusExtendedSelectorById } from '../selectors/status';
import { useDevice } from '../../devices/hooks/useDevice';
import CUActionsAPI from '../../CUActions/api/CUActionsAPI';
import { CU_POLLING_INTERVAL } from '../../CUActions/api/CUActions.constants';
import useWatchError from '../../../handlingErrors/useWatchError';
import { getCUActionsResponseByActionId } from '../../CUActions/selectors/CUActionsAPISelectors';
import { Base64 } from '../../../util/Base64';
import { getColorFromSensor } from '../api/util/sensor/getColorFromSensor';
import { useColorStatusFromCU } from '../api/util/sensor/getColorStatusFromCU';

/**
 * Get and fetch all the data needed from specific control unit, but loading all the controlUnits from devices related
 * @param encodedDeviceId
 * @param controlUnitId
 * @param onError
 * @returns {
    loading,
    currentEvents,
    currentAlarms,
    currentSensorData,
    hasAlarms,
    onEvent,
    mainSensor,
    type,
    CUTranslate,
  }
 */
export function useCurrentStateByControlUnitId(
  encodedDeviceId: string,
  controlUnitId: string,
  onError: (error: unknown) => unknown,
  refreshTrigger?: number | undefined
) {
  const [device, loadingDevice] = useDevice(encodedDeviceId);

  const [currentEvents, loadingCurrentEvents] = useCurrentEventsControlUnitById(
    encodedDeviceId,
    controlUnitId,
    onError,
    refreshTrigger
  );
  const {
    error: actionsCollectionError,
    isLoading: actionsCollectionIsLoading,
  } = CUActionsAPI.useGetActionStatusQuery(Base64.decode(encodedDeviceId), {
    skip: !encodedDeviceId,
    refetchOnMountOrArgChange: true,
    pollingInterval: CU_POLLING_INTERVAL,
  });
  useWatchError(actionsCollectionError);

  const [controlUnit, loadingControlUnits] = useControlUnitById(
    encodedDeviceId,
    controlUnitId,
    onError,
    refreshTrigger
  );

  const [currentSensorDataControlUnits, loadingCurrentSensorData] =
    useCurrentSensorDataControlUnitsByDeviceId(
      encodedDeviceId,
      onError,
      refreshTrigger
    );
  const currentSensorData = currentSensorDataControlUnits[controlUnitId];
  const filterAlarms = (event: DigitalChannelEventMixed) =>
    filterAlarmsFromEvents(event.metadata, event);

  const [CUTranslateDevice, loadingCUTranslate] =
    useCUTranslate(encodedDeviceId);

  const loading = !!(
    loadingDevice ||
    loadingControlUnits ||
    loadingCurrentEvents ||
    loadingCurrentSensorData ||
    loadingCUTranslate ||
    actionsCollectionIsLoading
  );

  // Alarms
  const currentAlarms = useMemo(() => {
    return Object.values(
      (currentEvents as Record<string, DigitalChannelEventMixed>) ?? {}
    ).filter(filterAlarms);
  }, [currentEvents]);

  // Sensor
  const mainSensor = getMainSensor(
    currentSensorDataControlUnits[controlUnitId]
  );

  const hasAlarms = currentAlarms.length;
  const onEvent = currentEvents?.[DEFAULT_EVENT_ON];
  const onActionCollection = useAppSelector((state) =>
    getCUActionsResponseByActionId(state, {
      actionId: DEFAULT_EVENT_ON,
      deviceId: encodedDeviceId,
      controlUnitId,
    })
  );

  const isOn =
    onActionCollection.data?.value ??
    (onEvent && getDigitalEventValue(onEvent.value, onEvent?.metadata));

  const color = getColorFromSensor(
    loading,
    hasAlarms,
    isOn,
    device.cloudConnectivity
  );
  const colorStatus = useColorStatusFromCU(
    loading,
    hasAlarms,
    isOn,
    device.cloudConnectivity
  );

  // SetPoints
  const setPoints = useAppSelector((state) =>
    getSetPointsSelectorById(state, {
      deviceId: encodedDeviceId,
      controlUnitId,
    })
  );

  // Actions
  const actions = useAppSelector((state) =>
    getActionExtendedSelectorById(state, {
      deviceId: encodedDeviceId,
      controlUnitId,
    })
  );

  // Status
  const status = useAppSelector((state) =>
    getStatusExtendedSelectorById(state, {
      deviceId: encodedDeviceId,
      controlUnitId,
    })
  );

  const CUTranslate = (keyTranslation: string, ...otherArgs: any[]) =>
    CUTranslateDevice(keyTranslation, controlUnitId, ...otherArgs);

  return {
    loading,
    controlUnit,
    currentEvents,
    currentAlarms,
    currentSensorData,
    setPoints,
    hasAlarms,
    actions,
    status,
    isOn,
    onEvent,
    mainSensor,
    color,
    colorStatus,
    cloudConnectivity: device.cloudConnectivity,
    CUTranslate,
  };
}
