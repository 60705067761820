// TODO: remember for changing from original file
import React, { useMemo } from 'react';
import { Container, Box } from '@mui/material';
import { getModuleSelected, navigationPath } from '../navigations/navigationPath';
import {
  getGroups,
  getGroupSelectedByQueryParamSelector,
} from '../../../redux/groups/selectors';
import { warmUpGroups } from '../../../redux/groups/actions/thunks';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../redux/store.model';
import { PolyglotComponentProps, withPolyglot } from '../../../i18n';
import { useGroupById } from '../../../redux/groups/hooks/useGroupById';
import withHandlingErrors, {
  HandlingErrorWrappedProps,
} from '../../../handlingErrors';
import GroupCard from './Overview/GroupsOverview/GroupCard';
import { RouterComponentProps, withRouter } from '../../../util/route-dom';
import { Outlet } from 'react-router-dom';
import NavBackButton from '../navigations/NavBackButton';

interface GroupManagerLayoutProps
  extends ConnectedComponentProps,
    PolyglotComponentProps,
    HandlingErrorWrappedProps,
    RouterComponentProps<{ groupId?: string | undefined }> {}
export function GroupManagerLayoutSimple(props: GroupManagerLayoutProps) {
  const { params, groups, handlingErrorsApi } = props;
  const { groupId } = params;

  const [group, loading] = useGroupById(groupId, handlingErrorsApi);
  const { pathname } = window.location;

  /* Navigation */
  const moduleSelected = useMemo(() => {
    return getModuleSelected(pathname);
  }, [pathname]);
  const sectionsNavigation = navigationPath(
    props,
    groupId,
    groups,
    group,
    moduleSelected
  );

  return (
    <Container
      maxWidth="xl"
      disableGutters
      sx={{
        height: '100%',
      }}
    >
      {groupId && (
        <GroupCard
          group={group}
          moduleSelected={moduleSelected}
          groupId={groupId}
          loading={loading}
        />
      )}
      {sectionsNavigation.length > 1 && (
        <Box sx={{ mx: { xs: 1, md: 3 } }}>
          <NavBackButton path={sectionsNavigation} />
        </Box>
      )}
      <Container maxWidth="lg" disableGutters sx={{ py: 2 }}>
        <Outlet />
      </Container>
    </Container>
  );
}

const mapStateToProps = (
  state: RootState,
  props: RouterComponentProps<{ groupId?: string | undefined }>
) => ({
  groups: getGroups(state),
  group: getGroupSelectedByQueryParamSelector(state, props),
});

const connector = connect(mapStateToProps, {
  warmUpGroups,
});
type ConnectedComponentProps = ConnectedProps<typeof connector>;

export default withHandlingErrors(
  withPolyglot(withRouter(connector(GroupManagerLayoutSimple)))
);
