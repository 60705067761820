import { createSelector } from '@reduxjs/toolkit';
import {
  getControlUnitById,
  getControlUnitsSensorDataByControlUnitId,
  getSensorDataMetadataSelector,
} from '.';
import { getSetPoints } from '../api/util/setPoint/getSetPoints';
import { getMixedCurrentEventsFromId } from './events';

export const getSetPointSelectorById = createSelector(
  [getControlUnitById],
  (controlUnit) => {
    return controlUnit?.setpoints;
  }
);

export const getSetPointsSelectorById = createSelector(
  [
    getSetPointSelectorById,
    getMixedCurrentEventsFromId,
    getSensorDataMetadataSelector,
    getControlUnitsSensorDataByControlUnitId,
  ],
  getSetPoints
);
