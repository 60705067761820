import { Box, Button, Stack } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import React, { useState } from 'react';
import { ComposedParametersValue } from '../../../../../../redux/CUParameters/selectors';
import { ParameterProps } from './Parameter';
import { ParameterEditControl } from './ParameterEditControl/ParameterEditControl';
import { getTitle } from '../../../../../../redux/CUParameters/api/util/parameterUtil';
import { SingleData } from '../../../../../../theme/components';

export interface ParameterEditProps extends ParameterProps {
  onClickClose: () => void;
  onSave: (parameter: ComposedParametersValue, newValue: string) => void;
  loading?: boolean;
}
export function ParameterEdit(props: ParameterEditProps) {
  const {
    parameter,
    loading,
    onClickClose,
    onSave,
    polyglot,
    handlingErrorsApi,
  } = props;

  const title = getTitle(parameter);
  const [temporalValue, setTemporalValue] = useState(parameter.value || '');
  const [errorForm, setErrorForm] = useState<string | null | undefined>(
    undefined
  );
  const onClickSave = () => {
    if (errorForm) return handlingErrorsApi(new Error(errorForm));
    onSave(parameter, temporalValue);
  };
  const onChange = (value: string, error?: string) => {
    setTemporalValue(value);
    setErrorForm(error);
  };
  return (
    <>
      <SingleData
        key={parameter.name}
        header={title}
        body={
          <Box m={1}>
            <ParameterEditControl
              {...props}
              value={temporalValue}
              onChangeValue={onChange}
            />
            <Stack
              direction={'row'}
              justifyContent={'flex-end'}
              mt={2}
              spacing={1}
              fontSize={'1rem'}
            >
              <Button
                onClick={onClickClose}
                variant="text"
                disabled={loading}
                size="small"
              >
                {polyglot.t('button.cancel')}
              </Button>
              <Button
                onClick={onClickSave}
                variant="contained"
                disabled={loading}
                size="small"
              >
                {loading ? (
                  <CircularProgress size={24} />
                ) : (
                  polyglot.t('button.save')
                )}
              </Button>
            </Stack>
          </Box>
        }
        description={parameter.descriptionTranslation}
      />
    </>
  );
}
