import React, { useCallback, useMemo } from 'react';

import { getPolyglot } from '../../../i18n';

import { GridColDef } from '@mui/x-data-grid';
import { Link as LinkRouter } from 'react-router-dom';
import {
  Button,
  ButtonBase,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Base64 } from '../../../util/Base64';
import { PolicyAPIResponse } from '../../../redux/incident/api/Policy.model';
import { Device } from '../../../redux/devices/api/device.model';
import { useAppSelector } from '../../../redux/store.model';
import ControlUnitButton from '../../DeviceManager/ControlUnit/ControlUnitPage/ControlUnitButton/ControlUnitButton';
import { getControlUnitByIdGetterSelector } from '../../../redux/controlUnit/selectors/table';
import AlarmsListItem from '../AlarmBox/AlarmsListItem';
import {
  AlarmRedCercleIcon,
  CheckGreenCercleIcon,
  UserInterfaceIcon,
} from '../../../theme/icons';
import { GridInitialStateCommunity } from '@mui/x-data-grid/models/gridStateCommunity';

interface IncidentListProps {
  workspaceId: string;
  policiesData: PolicyAPIResponse[] | undefined;
  devices: Device[];
}
export function useIncidentsDefDataGrid(props: IncidentListProps) {
  const { workspaceId, policiesData, devices } = props;
  const polyglot = getPolyglot();
  const getControlUnit = useAppSelector(getControlUnitByIdGetterSelector);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const initialState: GridInitialStateCommunity = {};
  const rowHeight = isMobile ? 200 : undefined;
  const getRowHeight = useCallback(() => rowHeight, [rowHeight]);

  const columns: GridColDef[] = useMemo(
    () =>
      isMobile
        ? [
            // Use timestamp as principal cell to render the row
            // to have a sortable behavior on timestamp
            {
              field: 'timestamp',
              align: 'left',
              flex: 1,
              headerName: polyglot.t('incident.table_header_title'),
              sortable: false,
              renderCell: (params) => {
                const item = params.row;
                return (
                  <ButtonBase
                    component={LinkRouter}
                    to={`/alarm/${item.uuid}`}
                    sx={{
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <AlarmsListItem
                      deviceId={item.deviceId}
                      device={
                        Object.values(devices).find(
                          (device) =>
                            Base64.decode(device.uuid) === item.deviceId
                        )?.name ?? ''
                      }
                      resolutionTimestamp={item.resolutionTimestamp}
                      creationTimestamp={item.timestamp}
                      error={!item.resolutionTimestamp}
                      title={
                        item.eventTranslation?.shortText ||
                        item.event ||
                        item.summary
                      }
                      controlUnit={item.cu}
                    />
                  </ButtonBase>
                );
              },
            },
          ]
        : [
            {
              field: 'status',
              align: 'center',
              headerName: polyglot.t('incident.status'),
              sortable: false,

              renderCell: (params) => {
                const item = params.row;
                return item && item?.resolutionTimestamp ? (
                  <CheckGreenCercleIcon height={'3em'} />
                ) : (
                  <AlarmRedCercleIcon height={'3em'} />
                );
              },
            },
            {
              field: 'device',
              align: 'left',
              flex: 1,
              headerName: polyglot.t('incident.device'),
              valueGetter: (params) => {
                const item = params.row;
                return Object.values(devices).find(
                  (device) => Base64.decode(device.uuid) === item.deviceId
                )?.name;
              },
              renderCell: (params) => {
                const item = params.row;
                return (
                  <Link
                    component={LinkRouter}
                    to={`/devicemanager/device/${Base64.encode(
                      item.deviceId
                    )}/device-detail/`}
                    sx={{ width: '100%' }}
                  >
                    <Button
                      variant="text"
                      endIcon={<UserInterfaceIcon />}
                      color={item.resolutionTimestamp ? 'primary' : 'error'}
                      sx={{
                        overflowWrap: 'anywhere',
                        justifyContent: 'flex-start',
                      }}
                      fullWidth
                    >
                      <Typography
                        variant="h6"
                        fontSize={'1rem'}
                        textAlign={'left'}
                        sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
                      >
                        {Object.values(devices).find(
                          (device) =>
                            Base64.decode(device.uuid) === item.deviceId
                        )?.name ?? item.deviceId}
                      </Typography>
                    </Button>
                  </Link>
                );
              },
            },
            {
              field: 'cu',
              align: 'left',
              headerName: polyglot.t('incident.cu'),
              sortable: false,
              flex: 1,
              valueGetter: (params) => {
                const item = params.row;
                return (
                  getControlUnit({
                    deviceId: item.deviceId,
                    controlUnitId: item.cu,
                  })?.name || item.cu
                );
              },
              renderCell: (params) => {
                const item = params.row;
                return (
                  <ControlUnitButton
                    variant="text"
                    deviceId={item?.deviceId}
                    controlUnitId={item?.cu}
                    color={item.resolutionTimestamp ? 'primary' : 'error'}
                    fullWidth
                    sx={{
                      overflowWrap: 'anywhere',
                      justifyContent: 'flex-start',
                    }}
                  />
                );
              },
            },
            {
              field: 'title',
              align: 'left',
              headerName: polyglot.t('incident.summary'),
              sortable: false,
              flex: 1.5,
              valueGetter: (params) => {
                const item = params.row;
                const title = item.eventTranslation?.shortText;
                return title || item.event || item.summary;
              },

              renderCell: (params) => {
                const item = params.row;
                return (
                  <Link
                    component={LinkRouter}
                    to={`/alarm/${item.uuid}`}
                    sx={{ width: '100%' }}
                  >
                    <Button
                      variant="text"
                      endIcon={<UserInterfaceIcon />}
                      color={item.resolutionTimestamp ? 'primary' : 'error'}
                      sx={{
                        overflowWrap: 'anywhere',
                        justifyContent: 'flex-start',
                      }}
                      fullWidth
                    >
                      <Typography
                        variant="h6"
                        fontSize={'1rem'}
                        textAlign={'left'}
                        sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
                      >
                        {params.value}
                      </Typography>
                    </Button>
                  </Link>
                );
              },
            },
            {
              field: 'description',
              flex: 1.5,
              align: 'left',
              headerName: polyglot.t('incident.description'),
              sortable: false,
              valueGetter: (params) => {
                const item = params.row;
                return item.eventTranslation?.longText || item.description;
              },
              renderCell: (params) => (
                <Typography
                  variant="h6"
                  fontSize={'1rem'}
                  textAlign={'left'}
                  sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
                >
                  {params.value}
                </Typography>
              ),
            },
            {
              field: 'timestamp',
              align: 'left',
              headerName: polyglot.t('incident.created_at'),
              sortable: false,
              renderCell: (params) => (
                <Typography
                  variant="h6"
                  fontSize={'1rem'}
                  textAlign={'left'}
                  sx={{ whiteSpace: 'break-spaces' }}
                >
                  {new Date(params.value).toLocaleString()}
                </Typography>
              ),
            },
            {
              field: 'resolutionTimestamp',
              align: 'left',
              headerName: polyglot.t('incident.solved_at'),
              sortable: false,
              renderCell: (params) => (
                <Typography
                  variant="h6"
                  fontSize={'1rem'}
                  textAlign={'left'}
                  sx={{ whiteSpace: 'break-spaces' }}
                >
                  {params.value ? new Date(params.value).toLocaleString() : ''}
                </Typography>
              ),
            },
            {
              field: 'policy',
              flex: 1,
              align: 'left',
              headerName: polyglot.t('incident.trigger'),
              sortable: false,
              valueGetter: (params) => {
                const item = params.row;
                return policiesData?.find(
                  (policy) => policy.uuid === item.policyId
                )?.title;
              },
              renderCell: (params) => {
                const item = params.row;
                return (
                  <Link
                    component={LinkRouter}
                    to={`/stoerk-id/my-stoerk-id/workspace/${workspaceId}/policy/${item.policyId}`}
                    sx={{ width: '100%' }}
                  >
                    <Button
                      fullWidth
                      variant="text"
                      endIcon={<UserInterfaceIcon />}
                      color={item.resolutionTimestamp ? 'primary' : 'error'}
                      sx={{
                        overflowWrap: 'anywhere',
                        justifyContent: 'flex-start',
                      }}
                    >
                      <Typography
                        variant="h6"
                        fontSize={'1rem'}
                        textAlign={'left'}
                      >
                        {policiesData?.find(
                          (policy) => policy.uuid === item.policyId
                        )?.title ?? item.policyId}
                      </Typography>
                    </Button>
                  </Link>
                );
              },
            },
          ],
    [isMobile, devices, getControlUnit, policiesData, polyglot, workspaceId]
  );
  return { columns, disableColumnMenu: isMobile, initialState, getRowHeight };
}
