import React from 'react';
import {
  CircularProgress,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
} from '@mui/material';
import { StoerkIdRequestAPI } from '../../../../redux/stoerkID/StoerkIdAPI.model';
import LabelTitle from '../../../../theme/components/Forms/LabelTitle';
import { getPolyglot } from '../../../../i18n';
import StoerkIdAPI from '../../../../redux/stoerkID/StoerkIdAPI';
import { useShowErrorMessage } from '../../../../handlingErrors';
export interface StoerkIdRequestAutoChargeProps {
  request: StoerkIdRequestAPI;
  showAutoCharge?: boolean;
  editAutoCharge?: boolean;
  isLastRequest?: boolean;
}
function StoerkIdRequestAutoCharge(props: StoerkIdRequestAutoChargeProps) {
  const { request, showAutoCharge, editAutoCharge, isLastRequest } = props;
  const polyglot = getPolyglot();
  const [setAutoCharge, { isLoading: isSetAutoChargeLoading }] =
    StoerkIdAPI.useSetAutoChargeFromRequestMutation();
  const { isFetching: isGetMyRequestsFetching } =
    StoerkIdAPI.useGetMyRequestsQuery(undefined);
  const showErrorMessage = useShowErrorMessage();
  const handleChange = () => {
    setAutoCharge({
      requestId: request?.requestId,
      autocharge: !request.autocharge,
    })
      .unwrap()
      .catch(showErrorMessage);
  };
  const isLoading = isGetMyRequestsFetching || isSetAutoChargeLoading;
  if (showAutoCharge && editAutoCharge && isLastRequest) {
    return (
      <Grid item xs={4} md={4}>
        <LabelTitle>
          {polyglot.t('stoerk_id.request_form.autocharge')}
          {isLoading && <CircularProgress size={'1em'} sx={{ ml: 1 }} />}
        </LabelTitle>
        <FormControlLabel
          control={
            <Switch
              disabled={isLoading}
              checked={request.autocharge}
              name="autocharge"
              color="primary"
              onChange={handleChange}
            />
          }
          label={polyglot.t('stoerk_id.request_form.autocharge')}
        />
      </Grid>
    );
  }
  if (showAutoCharge) {
    return (
      <Grid item xs={4} md={4}>
        <LabelTitle>
          {polyglot.t('stoerk_id.request_form.autocharge')}
        </LabelTitle>
        <Typography variant="body2">
          {request.autocharge
            ? polyglot.t('stoerk_id.request_form.autocharge_note_active')
            : polyglot.t('stoerk_id.request_form.autocharge_note_inactive')}
        </Typography>
      </Grid>
    );
  }

  return null;
}

export default StoerkIdRequestAutoCharge;
