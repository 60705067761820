import { useEffect, useState } from 'react';
import { RootState, useAppDispatch, useAppSelector } from '../../store.model';
import { warmUpGroups } from '../actions/thunks';
import { getGroups, isGroupsLoading } from '../selectors';

/**
 * Get and fetch group from groupID
 * @param deviceId
 * @returns [group, loading, error]
 * 
 * @usage
´´´ts
const [group, loading, error] = useGroupById("UUUID_GROUP")
´´´
 */
export function useGroups({
  onError,
}: {
  onError?: (error: unknown) => unknown;
} = {}) {
  const groups = useAppSelector(getGroups);

  const activatedLoadingGroupsDevices = useAppSelector(
    (state: RootState) => state.groups.activatedLoadingGroupsDevices
  );
  const groupsLoading = useAppSelector(isGroupsLoading);
  const [error, setError] = useState(undefined);
  const [fetched, setFetched] = useState(false);

  // initial fetch
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (!fetched)
      Promise.resolve()
        .then(() => {
          if (!activatedLoadingGroupsDevices && groups?.length === 0) {
            return dispatch(warmUpGroups());
          }
        })
        .catch((error) => {
          setError(error);
          onError && onError(error);
        })
        .finally(() => setFetched(true));
  });
  return { data: groups, isLoading: groupsLoading || !fetched, error };
}
