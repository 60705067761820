import React, { useState } from 'react';
import StoerkIdRequestLayout from './StoerkIdRequestLayout';
import { Box, Button, Container, Typography } from '@mui/material';
import { StorkBuildingIcon, SupplierBuildingIcon } from '../../../theme/icons';
import { useNavigate } from 'react-router-dom';
import useBack from '../../../util/route-dom/hooks/useBack';
import { getPolyglot } from '../../../i18n';
import ButtonPaper from '../../../theme/components/ButtonPaper';
import RequestProcessSteps from './RequestProcessSteps';

enum OptionSelected {
  STORK = 1,
  SUPPLIER = 2,
}
function StoerkIdRequestSelectSupplier() {
  const navigate = useNavigate();
  const polyglot = getPolyglot();
  const [optionSelected, setOptionSelected] = useState<OptionSelected | null>(
    null
  );
  const onClickBack = useBack();
  const onClickNext = () => {
    if (optionSelected === OptionSelected.STORK) {
      navigate('/stoerk-id/request/company-internal-identification');
    }
    if (optionSelected === OptionSelected.SUPPLIER) {
      navigate('/stoerk-id/request/oem-supplier');
    }
  };

  return (
    <StoerkIdRequestLayout>
      <RequestProcessSteps activeStep={0} onClickBack={onClickBack} />
      <Container
        maxWidth="md"
        disableGutters
        sx={{
          mt: 2,
        }}
      >
        <Box sx={{ mt: 2 }}>
          <Typography variant="h2">
            {polyglot.t('stoerk_id.request_form.select_supplier_tittle')}
          </Typography>
          <form onSubmit={onClickNext}>
            <Box
              sx={(theme) => ({
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                gap: theme.spacing(2),
                width: '100%',
                justifyItems: 'center',
                // mobile
                [theme.breakpoints.down('md')]: {
                  gridTemplateColumns: '1fr',
                },
              })}
            >
              <ButtonPaper
                selected={optionSelected === OptionSelected.STORK}
                onClick={() => setOptionSelected(OptionSelected.STORK)}
                sx={{ width: '100%', height: '25rem' }}
              >
                <StorkBuildingIcon height={'12rem'} />
                <div>
                  {polyglot.t(
                    'stoerk_id.request_form.select_supplier_option_1'
                  )}
                </div>
              </ButtonPaper>
              <ButtonPaper
                selected={optionSelected === OptionSelected.SUPPLIER}
                onClick={() => setOptionSelected(OptionSelected.SUPPLIER)}
                sx={{ width: '100%', height: '25rem' }}
              >
                <SupplierBuildingIcon height={'12rem'} />
                <div>
                  {polyglot.t(
                    'stoerk_id.request_form.select_supplier_option_2'
                  )}
                </div>
              </ButtonPaper>
            </Box>
            <Box
              sx={{
                textAlign: 'right',
                mt: 3,
              }}
            >
              <Button
                variant="contained"
                size="large"
                color="primary"
                type="submit"
                // sx={{ width: '100%' }}
                disabled={optionSelected === null}
              >
                {polyglot.t('stoerk_id.request_form.next_button')}
              </Button>
            </Box>
          </form>
          <Box sx={{ flexGrow: 1 }}></Box>
        </Box>
      </Container>
    </StoerkIdRequestLayout>
  );
}

export default StoerkIdRequestSelectSupplier;
