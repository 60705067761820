import React from 'react';
import { useAppSelector } from '../../../../../../redux/store.model';
import { getRightUserUtilSelector } from '../../../../../../redux/rights/selectors';
import { ParameterProps } from './Parameter';
import { getTitle, getValue } from '../../../../../../redux/CUParameters/api/util/parameterUtil';
import { SingleData } from '../../../../../../theme/components';
import { IconButton, Tooltip } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { EditIcon } from '../../../../../../theme/icons';

interface ParameterViewProps extends ParameterProps {
  onClickEdit: () => void;
}
export function ParameterView(props: ParameterViewProps) {
  const { parameter, onClickEdit, groupId, deviceId } = props;

  const value = getValue(parameter);
  const title = getTitle(parameter);
  const showEditButton = useAppSelector(
    getRightUserUtilSelector
  ).hasRightsToUpdateParameter(deviceId, groupId);

  return (
    <>
      <SingleData
        header={title}
        value={value}
        leftHeaderAdornment={
          parameter.changeable ? (
            <Tooltip
              title={parameter.descriptionTranslation}
              arrow
              color="textPrimary"
            >
              <IconButton size="small" color="primary">
                <HelpOutlineIcon color="primary" />
              </IconButton>
            </Tooltip>
          ) : undefined
        }
        rightHeaderAdornment={
          showEditButton && (
            <IconButton onClick={onClickEdit} size="small">
              <EditIcon />
            </IconButton>
          )
        }
      />
    </>
  );
}
