import {
  DeviceControlSetPointsMetadataAPIResponse,
  DeviceControlUnitsChannelMetadataAPIResponse,
} from '../../../../devices/api/device.model';
import {
  DigitalChannelEventMixed,
  SensordataNewest,
} from '../../../../monitoring/api/monitoring.model';
import {
  EVENT_NAME_SET1SET2,
  SETPOINT_1,
  SETPOINT_2,
  SETPOINT_3,
  SETPOINT_I_S01,
  SETPOINT_I_S01_ID,
  SETPOINT_I_S02,
  SETPOINT_I_S02_ID,
  SETPOINT_I_S03,
  SETPOINT_I_S03_ID,
} from '../constants';

const supportedChannelsSetPoints = [
  SETPOINT_I_S01,
  SETPOINT_I_S02,
  SETPOINT_I_S03,
];
const mapSetPointIdToChannelName: Record<string, string> = {
  [SETPOINT_I_S01_ID]: SETPOINT_1,
  [SETPOINT_I_S02_ID]: SETPOINT_2,
  [SETPOINT_I_S03_ID]: SETPOINT_3,
};
export const getSetPoints = (
  setPoints: DeviceControlSetPointsMetadataAPIResponse[] | undefined,
  actions: Record<string, DigitalChannelEventMixed | undefined>,
  channels: DeviceControlUnitsChannelMetadataAPIResponse[] | undefined,
  sensorData: Record<string, SensordataNewest | undefined> | undefined
) => {
  const channelsSetpoints = channels?.filter((channel) =>
    supportedChannelsSetPoints.includes(channel.id)
  );
  const supportedSetpoints = setPoints;
  // TODO uncomment this block
  // ?.filter((setPoint) =>
  //   channelsSetpoints?.some(
  //     (channel) => channel.name === mapSetPointIdToChannelName[setPoint.id]
  //   )
  // );

  const mappedSupportedSetpoints = supportedSetpoints?.map((setPoint) => {
    const action = actions?.[mapSetPointIdToChannelName[setPoint.id]];
    const channel = channelsSetpoints?.find(
      (channel) => channel.name === mapSetPointIdToChannelName[setPoint.id]
    );
    const sensor = sensorData?.[channel?.id ?? ''];
    return {
      setPoint,
      action,
      channel,
      sensorData: sensor,
    };
  });

  return mappedSupportedSetpoints;
  // TODO uncomment this block
  // EVENT_NAME_SET1SET2 case
  // const actionSet1Set2 = actions?.[EVENT_NAME_SET1SET2];
  // if (!(actionSet1Set2 && actionSet1Set2.state === 0)) {
  //   return mappedSupportedSetpoints;
  // } else {
  //   const setPointToSearch =
  //     // if the value is 0, then the used set point is 1.
  //     actionSet1Set2.value == '0'
  //       ? SETPOINT_1
  //       : // if the value is 1, then the used setPoint is 2
  //         SETPOINT_2;
  //   const currentSetPoints = mappedSupportedSetpoints?.find(
  //     (setPoint) => setPoint.channel?.name === setPointToSearch
  //   );

  //   if (currentSetPoints) {
  //     return [currentSetPoints];
  //   }
  //   return [];
  // }
};
