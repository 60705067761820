import { FeatureToggleState } from '../redux/featureToggle/featureToggle.model';
import togglesJson from './featureToggles.json';
import togglesJsonOFC from '../customized/ofc-serenity-eu/featureToggles.json';
import togglesJsonHengel from '../customized/hengel-eu/featureToggles.json';
import togglesJsonAllanNuttall from '../customized/alan-nuttall-flexeserve-eu/featureToggles.json';
import togglesJsonFoursFringand from '../customized/fours-fringand-fr/featureToggles.json';

/** load from json file */
let initialTogglesDictionary: FeatureToggleState = togglesJson;

/** load from venv */
const FEATURE_TOGGLE_PREFIX = 'REACT_APP_FEATURE_TOGGLE_';
const featureKeys = Object.keys(process.env).filter((key) =>
  key.startsWith(FEATURE_TOGGLE_PREFIX)
);
featureKeys.forEach((key) => {
  let value: string | boolean = process.env[key] || false;
  // parse boolean
  if (value === 'false') value = false;
  if (value === 'true') value = true;
  const keyWithOutPrefix = key.replace(
    FEATURE_TOGGLE_PREFIX,
    ''
  ) as keyof FeatureToggleState;
  initialTogglesDictionary[keyWithOutPrefix] = value as any;
});

/** load from customized */
const customizedTogglesDictionary: Record<string, FeatureToggleState> = {
  'ofc-serenity-eu': togglesJsonOFC,
  'alan-nuttall-flexeserve-eu': togglesJsonAllanNuttall,
  'hengel-eu': togglesJsonHengel,
  'fours-fringand-fr': togglesJsonFoursFringand,
};
if (
  initialTogglesDictionary['styledComponentsVariants'] &&
  typeof initialTogglesDictionary['styledComponentsVariants'] == 'string'
) {
  initialTogglesDictionary = Object.assign(
    initialTogglesDictionary,
    customizedTogglesDictionary[
      initialTogglesDictionary['styledComponentsVariants']
    ]
  );
}

export default initialTogglesDictionary;
