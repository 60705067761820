import React, { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getPolyglot } from '../../../../i18n';
import useBack from '../../../../util/route-dom/hooks/useBack';
import PolicyAPI from '../../../../redux/incident/api/PolicyAPI';
import {
  Button,
  CircularProgress,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { ButtonBack } from '../../../../theme/components';
import SaveIcon from '@mui/icons-material/Save';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  PolicyFilterType,
  PolicyInitialValuesProps,
  SourceTypes,
} from '../../../../redux/incident/api/Policy.model';
import { ErrorText } from '../../../../theme/components/Forms';
import MultipleSelect from '../../../DeviceManager/Group/GroupSettingsPage/Incidents/MultipleSelect';
import useWatchError from '../../../../handlingErrors/useWatchError';
import AlertFromError from '../../../../handlingErrors/AlertFromError';

function PolicyCreatePage() {
  const polyglot = getPolyglot();
  const params = useParams<{
    workspaceId: string;
  }>();

  const onClickBack = useBack('../');
  const navigate = useNavigate();

  const {
    data: possibleEventIds = [],
    isLoading: isLoadingEventIds,
    error: errorEventIds,
  } = PolicyAPI.useGetEventsIdsByWorkspaceIdQuery(params.workspaceId, {
    skip: !params.workspaceId,
  });
  const sortedEventIds = useMemo(
    () => possibleEventIds.slice().sort(),
    [possibleEventIds]
  );

  const [createPolicy, { error: errorCreate }] =
    PolicyAPI.useCreatePolicyMutation();

  const error = errorCreate || errorEventIds;
  useWatchError(error);

  const initialValues: PolicyInitialValuesProps = {
    title: '',
    description: '',
    filterValues: ['a'],
    isGroupFilter: true,
  };

  const validationSchema = useMemo(
    () =>
      yup.object({
        title: yup
          .string()
          .required(polyglot.t('incident.policy.title_required')),
        description: yup
          .string()
          .required(polyglot.t('incident.policy.description_required')),
        filterValues: yup
          .array()
          .of(yup.string())
          .min(1, polyglot.t('incident.policy.filter_required'))
          .required(polyglot.t('incident.policy.filter_required')),
      }),
    [polyglot]
  );

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, helpers) => {
      helpers.setSubmitting(true);
      await createPolicy({
        policy: {
          title: values.title,
          description: values.description,
          source: SourceTypes.EVENTS,
          filters: values.isGroupFilter
            ? [
                {
                  type: PolicyFilterType.EVENT_GROUP_FILTER,
                  spec: {
                    groups: values.filterValues,
                  },
                },
              ]
            : [
                {
                  type: PolicyFilterType.EVENT_ID_FILTER,
                  spec: {
                    ids: values.filterValues,
                  },
                },
              ],
        },
        workspaceId: params.workspaceId,
      });
      helpers.setSubmitting(false);
      if (!errorCreate) {
        navigate('/stoerk-id/my-stoerk-id');
      }
    },
  });

  return (
    <Container
      maxWidth="xl"
      sx={{
        height: '100%',
        py: 1,
      }}
    >
      <ButtonBack onClick={onClickBack}>
        {polyglot.t('stoerk_id.nav_title')}
      </ButtonBack>
      <div>
        <Typography variant="h1">
          {polyglot.t('incident.policy.policy_create_button')}
        </Typography>
        {error ? <AlertFromError error={error} /> : null}
        {formik.isSubmitting ? (
          <CircularProgress />
        ) : (
          <form onSubmit={formik.handleSubmit}>
            <Stack spacing={2}>
              <TextField
                fullWidth
                id="title"
                name="title"
                type="text"
                label={polyglot.t('incident.policy.policy_name')}
                value={formik.values.title}
                onChange={formik.handleChange}
                placeholder={polyglot.t('incident.policy.policy_name')}
                required
                error={formik.touched.title && Boolean(formik.errors.title)}
                helperText={
                  formik.touched.title &&
                  formik.errors.title && (
                    <ErrorText>{formik.errors.filterValues}</ErrorText>
                  )
                }
              />
              <TextField
                fullWidth
                id="description"
                name="description"
                type="text"
                label={polyglot.t('incident.policy.policy_description')}
                value={formik.values.description}
                onChange={formik.handleChange}
                placeholder={polyglot.t('incident.policy.policy_description')}
                required
                multiline
                rows={2}
                error={
                  formik.touched.title && Boolean(formik.errors.description)
                }
                helperText={
                  formik.touched.title &&
                  formik.errors.title && (
                    <ErrorText>{formik.errors.title}</ErrorText>
                  )
                }
              />
              <FormControl component="fieldset" fullWidth>
                <FormLabel component="legend">
                  {polyglot.t('incident.policy.filter_type')}
                </FormLabel>
                <RadioGroup
                  aria-label="filter-type"
                  name="isGroupFilter"
                  value={formik.values.isGroupFilter.toString()}
                  onChange={(e) => {
                    formik.handleChange(e);
                    formik.setFieldValue(
                      'isGroupFilter',
                      e.target.value === 'true'
                    );
                    formik.setFieldValue(
                      'filterValues',
                      e.target.value === 'true' ? ['a'] : []
                    );
                  }}
                >
                  <FormControlLabel
                    value="true"
                    control={<Radio />}
                    label={polyglot.t('incident.policy.all_alarms')}
                  />
                  <FormControlLabel
                    value="false"
                    control={<Radio />}
                    label={polyglot.t('incident.policy.select_alarms')}
                  />
                </RadioGroup>
              </FormControl>
              {formik.errors.filterValues && (
                <ErrorText>{formik.errors.filterValues}</ErrorText>
              )}
              {!formik.values.isGroupFilter &&
                (isLoadingEventIds ? (
                  <CircularProgress size={'2em'} />
                ) : (
                  <MultipleSelect
                    selectedOptions={formik.values.filterValues}
                    setSelectedOptions={formik.handleChange}
                    label={polyglot.t('incident.policy.available_alarms')}
                    displayOptions={sortedEventIds.map((item, index) => {
                      //TODO: support translations, we don't know how to solve this issue right now
                      return { id: index, value: item };
                    })}
                    SelectProps={{
                      id: 'filterValues',
                      name: 'filterValues',
                    }}
                  />
                ))}
              <Grid item xs={12}>
                <Grid container justifyContent="flex-end">
                  <Grid item xs={12} sm={2}>
                    <Button fullWidth startIcon={<SaveIcon />} type="submit">
                      {polyglot.t('button.save')}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Stack>
          </form>
        )}
      </div>
    </Container>
  );
}

export default PolicyCreatePage;
