import { Grid, Typography } from '@mui/material';
import React from 'react';
import { getPolyglot } from '../../../../i18n';
import getTextStatusFromRequest from '../../util/getStatusFromRequest';
import type { StoerkIdRequestStageProps } from './StoerkIdRequestStage';
import StoerkIdRequestAutoCharge from './StoerkIdRequestAutoCharge';
import LabelTitle from '../../../../theme/components/Forms/LabelTitle';

export function StoerkIdRequestDetail(props: StoerkIdRequestStageProps) {
  const { request, showBillingInfo, showStripeInfo } = props;

  const polyglot = getPolyglot();
  return (
    <Grid container spacing={1} padding={2} marginBottom={2}>
      <Grid item xs={6} md={4}>
        <LabelTitle>
          {polyglot.t('stoerk_id.detail_page.status.status_header_cell')}
        </LabelTitle>
        <Typography variant="body2">
          {polyglot.t(getTextStatusFromRequest(request))}
        </Typography>
      </Grid>

      <Grid item xs={6} md={4}>
        <LabelTitle>
          {polyglot.t('stoerk_id.detail_page.status.commanders_header_cell')}
        </LabelTitle>
        <Typography variant="body2">{request.deviceQuota}</Typography>
      </Grid>
      <Grid item xs={6} md={4}>
        <LabelTitle>
          {polyglot.t('stoerk_id.detail_page.status.users_header_cell')}
        </LabelTitle>
        <Typography variant="body2">{request.userQuota}</Typography>
      </Grid>
      <Grid item xs={12} md={4}>
        <LabelTitle>
          {polyglot.t('stoerk_id.detail_page.status.company_name_header_cell')}
        </LabelTitle>
        <Typography variant="body2">{request.workspaceName}</Typography>
      </Grid>

      {showBillingInfo && (
        <>
          {request.billingInfo?.firstName && (
            <Grid item xs={12} md={4}>
              <LabelTitle>
                {polyglot.t(
                  'stoerk_id.detail_page.status.billing_first_name_header_cell'
                )}
              </LabelTitle>
              <Typography variant="body2">
                {request.billingInfo?.firstName}
              </Typography>
            </Grid>
          )}
          {request.billingInfo?.lastName && (
            <Grid item xs={12} md={4}>
              <LabelTitle>
                {polyglot.t(
                  'stoerk_id.detail_page.status.billing_last_name_header_cell'
                )}
              </LabelTitle>
              <Typography variant="body2">
                {request.billingInfo?.lastName}
              </Typography>
            </Grid>
          )}
          {request.billingEmail && (
            <Grid item xs={12} md={4}>
              <LabelTitle>
                {polyglot.t(
                  'stoerk_id.detail_page.status.billing_email_header_cell'
                )}
              </LabelTitle>
              <Typography variant="body2">{request.billingEmail}</Typography>
            </Grid>
          )}
          {request.billingInfo?.country && (
            <Grid item xs={6} md={4}>
              <LabelTitle>
                {polyglot.t('stoerk_id.request_form.country')}
              </LabelTitle>
              <Typography variant="body2">
                {request.billingInfo?.country}
              </Typography>
            </Grid>
          )}
          {request.billingInfo?.line1 && (
            <Grid item xs={12} md={4}>
              <LabelTitle>
                {polyglot.t('stoerk_id.request_form.address_line1')}
              </LabelTitle>
              <Typography variant="body2">
                {request.billingInfo?.line1}
              </Typography>
            </Grid>
          )}
          {request.billingInfo?.line2 && (
            <Grid item xs={12} md={4}>
              <LabelTitle>
                {polyglot.t('stoerk_id.request_form.address_line2')}
              </LabelTitle>
              <Typography variant="body2">
                {request.billingInfo?.line2}
              </Typography>
            </Grid>
          )}

          {request.billingInfo?.postal_code && (
            <Grid item xs={6} md={4}>
              <LabelTitle>
                {polyglot.t('stoerk_id.request_form.postal_code')}
              </LabelTitle>
              <Typography variant="body2">
                {request.billingInfo?.postal_code}
              </Typography>
            </Grid>
          )}
          {request.billingInfo?.state && (
            <Grid item xs={6} md={4}>
              <LabelTitle>
                {polyglot.t('stoerk_id.request_form.state')}
              </LabelTitle>
              <Typography variant="body2">
                {request.billingInfo?.state}
              </Typography>
            </Grid>
          )}
          {request.billingInfo?.city && (
            <Grid item xs={6} md={4}>
              <LabelTitle>
                {polyglot.t('stoerk_id.request_form.city')}
              </LabelTitle>
              <Typography variant="body2">
                {request.billingInfo?.city}
              </Typography>
            </Grid>
          )}

          {request.billingInfo?.phoneNumber && (
            <Grid item xs={12} md={4}>
              <LabelTitle>
                {polyglot.t(
                  'stoerk_id.detail_page.status.billing_phone_header_cell'
                )}
              </LabelTitle>
              <Typography variant="body2">
                {request.billingInfo?.phoneNumber}
              </Typography>
            </Grid>
          )}
          {request.billingInfo?.vat && (
            <Grid item xs={12} md={4}>
              <LabelTitle>
                {polyglot.t('stoerk_id.request_form.vat_number')}
              </LabelTitle>
              <Typography variant="body2">
                {request.billingInfo?.vat}
              </Typography>
            </Grid>
          )}
          {showStripeInfo && request.stripeUserId && (
            <Grid item xs={4} md={4}>
              <LabelTitle>
                {polyglot.t(
                  'stoerk_id.detail_page.status.stripe_id_header_cell'
                )}
              </LabelTitle>
              <Typography variant="body2">{request.stripeUserId}</Typography>
            </Grid>
          )}
          <StoerkIdRequestAutoCharge {...props} />
        </>
      )}
      <Grid item xs={12}>
        <LabelTitle>
          {polyglot.t('stoerk_id.detail_page.status.price_header_cell')}
        </LabelTitle>
        <Typography variant="body2">
          {request?.pricingDetails?.text ?? '--'}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default StoerkIdRequestDetail;
