import { get } from 'lodash';
import React from 'react';
import { ComponentType } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../redux/store.model';
import { FeatureToggleState } from '../redux/featureToggle/featureToggle.model';
import { getFeatureToggle } from '../redux/featureToggle/selectors';

export type DictionaryComponents<T> = Record<string, T | Record<string, T>>;
interface Props<T> {
  DefaultComponent: T;
  dictionaryComponents: DictionaryComponents<T>;
  featureToggles: FeatureToggleState;
}

function getComponentOfFeatureToggle<T>(
  nameFeatureToggle: keyof FeatureToggleState,
  featureToggles: FeatureToggleState,
  dictionaryComponents: DictionaryComponents<T>
): T | undefined {
  const featureTogglesValue = featureToggles[nameFeatureToggle];
  if (!featureTogglesValue) return undefined;
  switch (typeof featureTogglesValue) {
    case 'string':
      return get(
        dictionaryComponents as Record<string, Record<string, T>>,
        [nameFeatureToggle, featureTogglesValue],
        undefined
      );

    case 'boolean':
      return get(
        dictionaryComponents as Record<string, T>,
        `${nameFeatureToggle}`,
        undefined
      );

    default:
      // eslint-disable-next-line no-console
      console.warn(
        `FeatureToggle type ${typeof featureTogglesValue} value is not supported, only is supported "string" and "boolean"`
      );
      return undefined;
  }
}

export function InferToggleComponent<T>(props: Props<T>) {
  const { DefaultComponent, dictionaryComponents, featureToggles } = props;
  let ComponentInfer = DefaultComponent;
  const firstNameFeatureToggle: any = Object.keys(props.featureToggles).find(
    (nameFeatureToggle: any) =>
      getComponentOfFeatureToggle(
        nameFeatureToggle,
        featureToggles,
        dictionaryComponents
      )
  );
  if (firstNameFeatureToggle)
    ComponentInfer = getComponentOfFeatureToggle(
      firstNameFeatureToggle,
      featureToggles,
      dictionaryComponents
    ) as T;

  return ComponentInfer;
}

const mapStateToProps = (state: RootState) => ({
  featureToggles: getFeatureToggle(state),
});
const connector = connect(mapStateToProps);
type ConnectedComponentProps = ConnectedProps<typeof connector>;
/**
 * HOC (High Order Component) ToggleComponent
 * To get the behavior of feature toggles. it use redux as its true state, so redux provider is a dependency,
 *
 * To use this ToggleComponent you should divide and encapsulate the components that
 * interfere with a feature and isolate them with this ToggleComponent and the correct configuration.
 *
 * @param DefaultComponent (required) is the component that it will render if not has a featureToggle enabled
 * @param dictionaryComponents is dictionary with feature toggle as a key and Component as a Value,
 * for feature toggle with boolean value, it would be a simple dictionary,
 * for feature toggle with string value, it would be a double nested dictionary,
 * @returns Component
 *
 * Example:
 * ```tsx
 * const exampleStateFeatureToggle = { styledComponentsVariants: 'alan-nuttall-flexeserve-eu', createVoucher: true}
 *
 * const ComponentToggled = ToggleComponent(ComponentDefault, {
 *              // styledComponentsVariants is a string featureToggle
 *              styledComponentsVariants: { 'alan-nuttall-flexeserve-eu': ComponentB },
 *              // createVoucher is a boolean featureToggle
 *              createVoucher: ComponentA,
 * });
 *
 * return <ComponentToggled sharedPropA={1} sharedPropA={"2"} />
 * ```
 * **NOTE: ** If many featureToggles are enabled, it will render the first Component that will found,
 * they are orderer in the same order that are loaded in the state,
 * so if will have { styledComponentsVariants: 'alan-nuttall-flexeserve-eu', createVoucher: true},
 * then it meet first styledComponentsVariants feature
 */
export function ToggleComponent<T>(
  DefaultComponent: ComponentType<T>,
  dictionaryComponents: DictionaryComponents<ComponentType<T>> = {}
) {
  const ToggleWrappedComponent = (props: T & ConnectedComponentProps) => {
    const { featureToggles, ...otherProps } = props;

    const ComponentInferred = InferToggleComponent({
      DefaultComponent,
      dictionaryComponents,
      featureToggles,
    });
    const forwardedProps: unknown = otherProps;
    return <ComponentInferred {...(forwardedProps as T)} />;
  };

  // @ts-ignore connect not support Generic Types
  const ToggleComponentConnected = connector(ToggleWrappedComponent);
  return ToggleComponentConnected;
}
