import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { getPolyglot } from '../../../../i18n';

interface TermsAndConditionsDialogProps {
  setIsRead: React.Dispatch<React.SetStateAction<boolean>>;
  open: boolean;
  closeModal: () => void;
  closeModalWithConfirmation: () => void;
  termsAndConditionsTextContent: React.ReactNode;
}

export default function TermsAndConditionsDialog({
  setIsRead,
  open,
  closeModal,
  closeModalWithConfirmation,
  termsAndConditionsTextContent,
}: TermsAndConditionsDialogProps) {
  const [reachedBottom, setReachedBottom] = useState<boolean>(false);
  const SCROLL_INTERVAL = 3;

  const polyglot = getPolyglot();

  const handleClose = () => {
    closeModal();
  };

  const handleWithConfirmationClose = () => {
    setIsRead(true);
    closeModalWithConfirmation();
  };

  const handleScroll = (e: any) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop <=
        e.target.clientHeight + SCROLL_INTERVAL &&
      e.target.scrollHeight - e.target.scrollTop >=
        e.target.clientHeight - SCROLL_INTERVAL;
    if (bottom) {
      setReachedBottom(true);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="h3">
          {polyglot.t('stoerk_id.request_form.agree_terms_link')}
        </Typography>
        <IconButton onClick={handleClose} aria-label="close">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        dividers={true}
        onScroll={handleScroll}
        sx={{ maxHeight: 780 }} //TODO: adjust basing on text, it must to be a scrollable modal
      >
        <DialogContentText
          id="scroll-dialog-description"
          tabIndex={-1}
          sx={{ paddingBottom: '1rem' }} //NOTE: keep always this padding bottom to well solve  e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        >
          {termsAndConditionsTextContent}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" variant="text">
          {polyglot.t('button.cancel')}
        </Button>
        <Button
          onClick={handleWithConfirmationClose}
          color="primary"
          disabled={!reachedBottom}
        >
          {polyglot.t('stoerk_id.request_form.agree_and_close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
