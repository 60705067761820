import React from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Menu,
  IconButton,
  useMediaQuery,
  useTheme,
  Grid as MUIGrid,
  Box,
  styled,
  CircularProgress,
  Button,
  Typography,
  alpha,
  ButtonProps,
} from '@mui/material';
import { uniqueId } from 'lodash';

const ItemContainer = styled(Button, {
  shouldForwardProp: (prop: any) =>
    !['showBorderBottom', 'error', 'warning', 'disabled', 'gutters'].includes(
      prop
    ),
})<ListItemProps>(
  ({ theme, showBorderBottom = true, error, warning, disabled }) => ({
    width: '100%',
    height: '6em',
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    gridTemplateRows: '1fr',
    alignItems: 'center',
    gridGap: '1em',
    borderRadius: '0px',
    pointer: 'cursor',
    // mobile
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr auto',
      fontSize: '10px',
    },
    ...(showBorderBottom && {
      borderBottomWidth: '1px',
      borderBottomStyle: 'solid',
      borderBottomColor: theme.palette.divider,
    }),
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.1),
      borderBottomColor: theme.palette.primary.main,
      '& .ListItem-Title': {
        color: '#2a2a2a',
      },
    },
    ...(error && {
      color: theme.palette.error.main,
      backgroundColor: alpha(theme.palette.error.main, 0.2),
      '& svg,.ListItem-Title,.ListItem-Subtitle': {
        color: theme.palette.error.main,
      },

      '&:hover': {
        backgroundColor: alpha(theme.palette.error.main, 0.3),
        borderColor: theme.palette.error.main,
      },
    }),
    ...(warning && {
      color: theme.palette.warning.contrastText,
      backgroundColor: alpha(theme.palette.warning.main, 0.2),
      borderColor: theme.palette.warning.main,
      '& svg,p': {
        color: theme.palette.warning.contrastText,
      },

      '&:hover': {
        backgroundColor: alpha(theme.palette.warning.main, 0.3),
        borderColor: theme.palette.warning.main,
      },
    }),

    ...(disabled && {
      color: theme.palette.text.disabled,
      backgroundColor: alpha(theme.palette.text.disabled, 0.05),
      borderColor: theme.palette.text.disabled,
      cursor: 'default',
      '& svg': {
        color: theme.palette.text.disabled,
      },
      '& p': {
        color: theme.palette.text.disabled,
      },

      '&:hover': {
        backgroundColor: alpha(theme.palette.text.disabled, 0.05),
        borderBottomColor: theme.palette.text.disabled,
      },
    }),
  })
);

const ItemLeftContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  flexGrow: 1,
}));

const ItemIconContainer = styled(Box)(({ theme }) => ({
  color: theme.palette.text.secondary,
  maxHeight: '100%',
  width: '4em',
  height: '4em',
  padding: '0.5em',
  marginRight: '1rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '& img,svg': {
    objectFit: 'contain',
    width: '100%',
    height: '100%',
  },
}));

const ItemTextContainer = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  maxWidth: '300px',
  [theme.breakpoints.down('md')]: {
    paddingLeft: '4px',
    maxWidth: '120px',
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  fontSize: '1.2em',
  /** ellipsis */
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  maxWidth: '18vw',
  [theme.breakpoints.down('md')]: {
    maxWidth: '130px',
  },
}));

const Subtitle = styled(Typography)(({ theme }) => ({
  fontSize: '0.8em',
  fontStyle: 'italic',
}));

export interface ListItemProps {
  id?: string;
  name?: React.ReactNode;
  subtitle?: React.ReactNode;
  label?: React.ReactNode;
  buttons?: React.ReactNode;
  showMenuOnResponsive?: boolean;
  menuButtons?: React.ReactNode;
  iconURL?: string | null;
  iconNode?: React.ReactNode;
  /** `true` by default */
  showBorderBottom?: boolean;
  className?: string | undefined;
  loading?: boolean;
  error?: Boolean;
  warning?: Boolean;
  /** Item is clickable */
  disabled?: boolean;
  onClick?: (event: any) => unknown;
  gutters?: boolean;
  ItemContainerProps?: ButtonProps;
}

export function ListItem(props: ListItemProps) {
  const {
    loading,
    iconURL,
    iconNode,
    className,
    error,
    warning,
    disabled,
    onClick,
    label,
    name,
    subtitle,
    id,
    buttons,
    showMenuOnResponsive = true,
    menuButtons = <DefaultMenuButtons {...props} />,
    ItemContainerProps,
  } = props;
  const onClickItem = (event: any) => !disabled && onClick && onClick(event);

  /** Tooltip */
  const theme = useTheme();
  const useTooltip =
    useMediaQuery(theme.breakpoints.down('md')) && showMenuOnResponsive;

  return (
    <ItemContainer
      //@ts-ignore
      component="div"
      variant="text"
      id={id}
      className={`ListItem ${className}`}
      onClick={onClickItem}
      disableRipple={disabled}
      disabled={disabled}
      error={error}
      warning={warning}
      gutters={false}
      {...ItemContainerProps}
    >
      <ItemLeftContainer>
        {/* icon */}
        <ItemIconContainer>
          {label}
          {loading ? (
            <CircularProgress size={'0.5rem'} />
          ) : iconURL ? (
            <img src={iconURL} alt={'item icon'} />
          ) : (
            iconNode
          )}
        </ItemIconContainer>

        {/* Device name */}
        <ItemTextContainer>
          <Title className="ListItem-Title">{name}</Title>
          <Subtitle className="ListItem-Subtitle" variant="body2">
            {subtitle}
          </Subtitle>
        </ItemTextContainer>
      </ItemLeftContainer>

      {useTooltip ? (
        menuButtons
      ) : (
        <MUIGrid
          item
          xs="auto"
          alignItems="end"
          onClick={(e) => e.stopPropagation()}
          onMouseDown={(e) => e.stopPropagation()}
        >
          {buttons}
        </MUIGrid>
      )}
    </ItemContainer>
  );
}
export function DefaultMenuButtons(props: ListItemProps) {
  const { buttons } = props;
  const [idTooltip] = React.useState(uniqueId('ListItem-Tooltip-'));
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  if (!buttons) return null;
  return (
    <MUIGrid
      item
      xs={1}
      textAlign="right"
      onClick={(e) => e.stopPropagation()}
      onMouseDown={(e) => e.stopPropagation()}
    >
      <IconButton
        id={`${idTooltip}-menu-button`}
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        edge="start"
        onClick={handleClick}
        color="inherit"
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id={`${idTooltip}-menu`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        MenuListProps={{
          'aria-labelledby': `${idTooltip}-menu-button`,
          sx: { paddingX: 1 },
        }}
      >
        {buttons}
      </Menu>
    </MUIGrid>
  );
}
export default ListItem;
