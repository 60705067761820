import React from 'react';
import {
  CircularProgress,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
} from '@mui/material';
import { WorkspaceAPI } from '../../../../redux/stoerkID/StoerkIdAPI.model';
import LabelTitle from '../../../../theme/components/Forms/LabelTitle';
import { getPolyglot } from '../../../../i18n';
import StoerkIdAPI from '../../../../redux/stoerkID/StoerkIdAPI';
import { useShowErrorMessage } from '../../../../handlingErrors';
export interface StoerkIdRequestAutoChargeProps {
  workspace: WorkspaceAPI;
  showAutoCharge?: boolean;
  editAutoCharge?: boolean;
}
function StoerkIdAutoCharge(props: StoerkIdRequestAutoChargeProps) {
  const { workspace, showAutoCharge, editAutoCharge } = props;
  const polyglot = getPolyglot();
  const [setAutoCharge, { isLoading: isSetAutoChargeLoading }] =
    StoerkIdAPI.useSetAutoChargeFromWorkspaceMutation();

  const { data: billingInfo, isLoading: isLoadingBillingInfo } =
    StoerkIdAPI.useGetBillingInfoFromWorkspaceQuery(workspace?.id, {
      skip: !workspace?.id,
    });
  const { isFetching: isGetMyWorkspacesFetching } =
    StoerkIdAPI.useGetMyWorkspacesQuery(undefined);
  const showErrorMessage = useShowErrorMessage();
  const handleChange = () => {
    setAutoCharge({
      workspaceId: workspace?.id,
      autocharge: !billingInfo?.autocharge,
    })
      .unwrap()
      .catch(showErrorMessage);
  };
  const isLoading =
    isGetMyWorkspacesFetching || isSetAutoChargeLoading || isLoadingBillingInfo;
  if (showAutoCharge && editAutoCharge) {
    return (
      <Grid item xs={4} md={4}>
        <LabelTitle>
          {polyglot.t('stoerk_id.request_form.autocharge')}
          {isLoading && <CircularProgress size={'1em'} sx={{ ml: 1 }} />}
        </LabelTitle>
        <FormControlLabel
          control={
            <Switch
              disabled={isLoading}
              checked={billingInfo?.autocharge}
              name="autocharge"
              color="primary"
              onChange={handleChange}
            />
          }
          label={polyglot.t('stoerk_id.request_form.autocharge')}
        />
      </Grid>
    );
  }
  if (showAutoCharge) {
    return (
      <Grid item xs={4} md={4}>
        <LabelTitle>
          {polyglot.t('stoerk_id.request_form.autocharge')}
        </LabelTitle>
        <Typography variant="body2">
          {billingInfo?.autocharge
            ? polyglot.t('stoerk_id.request_form.autocharge_note_active')
            : polyglot.t('stoerk_id.request_form.autocharge_note_inactive')}
        </Typography>
      </Grid>
    );
  }

  return null;
}

export default StoerkIdAutoCharge;
