import HeaderIconAlanNuttallFlexeserveEu from '../../../../customized/alan-nuttall-flexeserve-eu/components/auth/AuthLayout/HeaderIcon/HeaderIcon';
import HeaderIconFoursFringand from '../../../../customized/fours-fringand-fr/components/auth/AuthLayout/HeaderIcon/HeaderIcon';
import HeaderIconHengel from '../../../../customized/hengel-eu/components/auth/AuthLayout/HeaderIcon/HeaderIcon';
import HeaderIconOFC from '../../../../customized/ofc-serenity-eu/components/auth/AuthLayout/HeaderIcon/HeaderIcon';
import { ToggleComponent } from '../../../../featureToggle';
import HeaderIcon from './HeaderIcon';

export default ToggleComponent(HeaderIcon, {
  styledComponentsVariants: {
    'alan-nuttall-flexeserve-eu': HeaderIconAlanNuttallFlexeserveEu,
    'ofc-serenity-eu': HeaderIconOFC,
    'fours-fringand-fr': HeaderIconFoursFringand,
    'hengel-eu': HeaderIconHengel,
  },
});
