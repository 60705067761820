import React, { Component } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import ValidationUtil from '../../../util/ValidationUtil';
import { login, logOut } from '../../../redux/auth/actions';
import { PolyglotComponentProps, withPolyglot } from '../../../i18n';
import { RootState } from '../../../redux/store.model';
import LoginForm, { LoginFromValues } from './LoginForm';
import { getDefaultApplicationFeatureToggle } from '../../../featureToggle';
import AuthLayout from '../AuthLayout/AuthLayout';
import { withShowErrorMessage, ShowErrorMessageProps } from '../../HOC';
import { RouterComponentProps, withRouter } from '../../../util/route-dom';

interface LoginProps
  extends ConnectedComponentProps,
    RouterComponentProps,
    ShowErrorMessageProps,
    PolyglotComponentProps {}

interface LoginState {
  animation: any;
  duration: number;
  visible: boolean;
}

export class Login extends Component<LoginProps, LoginState> {
  validationUtil: ValidationUtil;
  constructor(props: LoginProps) {
    super(props);
    const { polyglot } = props;
    this.login = this.login.bind(this);
    this.validationUtil = new ValidationUtil(polyglot);
  }

  componentDidMount() {
    /** When the user is redirected to /login, we want logOuted user */
    this.props.logOut();
  }

  /**
   * Login
   * @param object event
   */
  async login(values: LoginFromValues) {
    const { navigate, showErrorMessage } = this.props;
    try {
      const { email, password, keepLogin } = values;
      await this.props.login(email, password, keepLogin);
      const queryParams = new URLSearchParams(this.props.location.search);
      const redirect =
        queryParams.get('redirect') ?? `/${this.props.defaultApplication}`;
      /* The user is redirected to the device manager without to wait to set the rights.
       In the device manager page a loader component is shown until all the data
       is loaded */
      navigate(redirect);
    } catch (error: any) {
      const errorDescription =
        typeof error === 'object' ? error?.message : error;
      showErrorMessage(errorDescription);
    }
  }

  render() {
    return (
      <AuthLayout>
        <LoginForm validationUtil={this.validationUtil} onSubmit={this.login} />
      </AuthLayout>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  loggedIn: state.auth.loggedIn,
  fetchingRightsCycle: state.auth.fetchingRightsCycle,
  defaultApplication: getDefaultApplicationFeatureToggle(state),
});
const connector = connect(mapStateToProps, { login, logOut });
type ConnectedComponentProps = ConnectedProps<typeof connector>;

export default connector(withShowErrorMessage(withRouter(withPolyglot(Login))));
