import React, { useState } from 'react';
import {
  Box,
  Button,
  Container,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import StoerkIdRequestLayout from '../StoerkIdRequestLayout';

import { getPolyglot } from '../../../../i18n';
import StoerkIdRequestOffers from './StoerkIdRequestOffers';
import { OffersValues } from './StoerkIdRequestOffer.model';
import { useFormik } from 'formik';
import useBack from '../../../../util/route-dom/hooks/useBack';
import RequestProcessSteps from '../RequestProcessSteps';

export default function StoerkIdRequestSelectPlan() {
  const polyglot = getPolyglot();
  const [plan, setPlan] = useState<OffersValues>('CUSTOM');
  const params = useParams<{
    companyName: string;
    companyInternalId: string;
  }>();
  const navigate = useNavigate();
  const onClickBack = useBack('/stoerk-id/company-internal-identification');

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const formik = useFormik({
    initialValues: {},
    onSubmit: async () => {
      const link =
        plan === 'STARTER'
          ? `/stoerk-id/request/${params.companyName}/${
              params.companyInternalId
            }/${plan.toLowerCase()}`
          : `/stoerk-id/request/${params.companyName}/${
              params.companyInternalId
            }/${plan.toLowerCase()}/form`;
      return navigate(link);
    },
  });

  return (
    <StoerkIdRequestLayout>
      <RequestProcessSteps activeStep={2} onClickBack={onClickBack} />

      <form onSubmit={formik.handleSubmit}>
        <Box sx={{ mt: 2 }}>
          <Container
            maxWidth="md"
            disableGutters
            sx={{
              mt: 1,
            }}
          >
            <Typography variant="h2">
              {polyglot.t('stoerk_id.request_form.select_plan_title')}
            </Typography>
            <Typography variant="body1" mt={0.5}>
              {polyglot.t('stoerk_id.request_form.select_plan_note')}
            </Typography>
          </Container>
          <Box
            sx={{
              width: '100%',
              display: isMobile ? 'flex' : 'grid',
              grid: isMobile ? 'none' : 'auto-flow / 1fr 2fr',
              justifyContent: 'center',
            }}
          >
            <StoerkIdRequestOffers onSelect={setPlan} value={plan} />
          </Box>
          <Box
            sx={{
              my: 1,
            }}
          ></Box>
          <Box
            sx={{
              textAlign: 'right',
            }}
          >
            <Button
              variant="contained"
              size="large"
              color="primary"
              type="submit"
            >
              {polyglot.t('stoerk_id.request_form.next_button')}
            </Button>
          </Box>
        </Box>
      </form>
    </StoerkIdRequestLayout>
  );
}
