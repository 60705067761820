import React, { Component } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import {
  RightsUserUtilComponentProps,
  withUserRightUtil,
} from '../../../util/rights';
import { PolyglotComponentProps, withPolyglot } from '../../../i18n';
import { logOut } from '../../../redux/auth/actions';
import './Header.css';
import { RootState } from '../../../redux/store.model';
import { AppBar, Button, Toolbar, Box } from '@mui/material';
import ConnectedAvatarMenu from './AvatarMenu/AvatarMenu';
import { HeaderIcon } from '../../auth/AuthLayout';
import { RouterComponentProps, withRouter } from '../../../util/route-dom';
import { ShowErrorMessageProps, withShowErrorMessage } from '../../HOC';
import AlarmBox from '../../Incident/AlarmBox/AlarmBox';
import showAlarmsSelector from '../../../redux/incident/selectors/showAlarmsSelector';

type Props = ConnectedComponentProps &
  RightsUserUtilComponentProps &
  ShowErrorMessageProps &
  RouterComponentProps &
  PolyglotComponentProps & {
    title?: string;
    logoIcon?: string;
  };

export class Header extends Component<Props> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showCreateVoucher: false,
    };
  }
  logOut = async () => {
    const { navigate, showErrorMessage } = this.props;
    try {
      await this.props.logOut();
      navigate('/');
    } catch (error: any) {
      showErrorMessage(error);
    }
  };

  render() {
    const {
      loggedIn,
      polyglot,
      navigate,
      avatarLocation,
      userName,
      userEmail,
      rightsUserUtil,
      showAlarms,
    } = this.props;

    const showCreateVoucher = rightsUserUtil.hasRightsToCreateVoucher();
    const userNameShow = !userName ? userEmail : userName;

    return (
      <AppBar
        position="static"
        color="transparent"
        className="AppBarMain"
        variant="elevation"
        sx={{
          alignItems: 'center',
        }}
      >
        <Toolbar
          sx={(theme) => ({
            height: '4rem',
            justifyContent: 'space-between',
            maxWidth: 1536,
            mx: 2,
            width: '100%',
            [theme.breakpoints.up('lg')]: {
              height: '6rem',
            },
          })}
        >
          <HeaderIcon />

          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            {loggedIn && showAlarms && (
              <Box mx={2}>
                <AlarmBox />
              </Box>
            )}
          </Box>
          <Box sx={{ display: 'flex' }}>
            {loggedIn && (
              <ConnectedAvatarMenu
                avatarLocation={avatarLocation}
                userNameShow={userNameShow}
                showCreateVoucher={showCreateVoucher}
                logOut={this.logOut}
              />
            )}
            {!loggedIn && (
              <Button
                onClick={() => navigate('/login')}
                variant="contained"
                color="primary"
                className="login"
                size="large"
              >
                {polyglot.t('nav.login')}
              </Button>
            )}
          </Box>
        </Toolbar>
      </AppBar>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  loggedIn: state.auth.loggedIn,
  userName: state.auth.userName,
  userEmail: state.auth.userEmail,
  avatarLocation: state.auth.avatarLocation,
  rights: state.rights.items,
  showAlarms: showAlarmsSelector(state),
});
const connector = connect(mapStateToProps, { logOut });
type ConnectedComponentProps = ConnectedProps<typeof connector>;

export default withShowErrorMessage(
  withRouter(withPolyglot(withUserRightUtil(connector(Header))))
);
