import { ToggleComponent } from '../featureToggle';

import ThemeProviderWithThemeST from './ThemeProviderWithTheme';
import ThemeProviderWithThemeAllanNuttall from '../customized/alan-nuttall-flexeserve-eu/theme/ThemeProviderWithTheme';
import ThemeProviderWithThemeHengel from '../customized/hengel-eu/theme/ThemeProviderWithTheme';
import ThemeProviderWithOFC from '../customized/ofc-serenity-eu/theme/ThemeProviderWithTheme';
import ThemeProviderWithFourFringand from '../customized/fours-fringand-fr/theme/ThemeProviderWithTheme';

const ThemeProviderWithTheme = ToggleComponent(ThemeProviderWithThemeST, {
  styledComponentsVariants: {
    'alan-nuttall-flexeserve-eu': ThemeProviderWithThemeAllanNuttall,
    'hengel-eu': ThemeProviderWithThemeHengel,
    'ofc-serenity-eu': ThemeProviderWithOFC,
    'fours-fringand-fr': ThemeProviderWithFourFringand,
  },
});

export default ThemeProviderWithTheme;
