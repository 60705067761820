import React from 'react';
import { ButtonIcon } from '../../../../../../theme/components';
import type { ControlUnitCardListProps } from '../ControlUnitCardList';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonthOutlined';

const iconId = 'events';
export function ControlUnitEventsAction(props: ControlUnitCardListProps) {
  const {
    polyglot,
    controlUnitId,
    groupId,
    deviceId,
    navigate,
    moduleSelected,
  } = props;
  const selected = moduleSelected === 'events';

  const openControlUnitEvents = (e: React.MouseEvent) => {
    if (selected) return;
    let url = `/devicemanager/device/${deviceId}/device-detail/control-unit/${controlUnitId}/events`;
    if (groupId) {
      url = `/devicemanager/device/${deviceId}/group/${groupId}/device-detail/control-unit/${controlUnitId}/events`;
    }
    navigate(url);
  };

  const tooltipContent = polyglot.t('device.events.events');

  return (
    <ButtonIcon
      onClick={openControlUnitEvents}
      selected={selected}
      className={`icon-${iconId} controlUnitId-${controlUnitId}`}
      tooltip={tooltipContent}
    >
      <CalendarMonthIcon />
    </ButtonIcon>
  );
}
