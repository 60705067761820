import OneSignal from 'react-onesignal';
import { getConfigurationByDomain } from './getConfigurationByDomain';

//@ts-ignore add OneSignalObj to debug
window.OneSignalObj = OneSignal || [];

const isAnIPAddress = !window.location.hostname.match(/[a-z]/i);

export async function initOneSignal() {
  if (isAnIPAddress) return;
  return OneSignal.init(getConfigurationByDomain());
}

export async function loadSessionOneSignal(userId: string) {
  if (isAnIPAddress) return;
  await OneSignal.showSlidedownPrompt();
  return OneSignal.setExternalUserId(userId);
}
