import { Box, Stack } from '@mui/material';
import React from 'react';
import {
  StoerkIdRequestAPI,
  WorkspaceAPI,
} from '../../../redux/stoerkID/StoerkIdAPI.model';
import StoerkIdHistory from './StoerkIdHistory';
import StoerkIdRequestStage from './StoerkIdRequestStage/StoerkIdRequestStage';
import StoerkIdStatus from './StoerkIdStatus/StoerkIdStatus';
import { StoerkIdTeamMembers } from './StoerkIdTeamMembers';
import CustomerActions from '../CustomerDetailPage/CustomerRequestActions/CustomerActions';
import SelectWorkspace from './SelectWorkspace';
import StoerkIdDevices from './StoerkIdDevices';
import CustomerWorkspaceActions from '../CustomerDetailPage/CustomerWorkspaceActions/CustomerWorkspaceActions';
import { useAppSelector } from '../../../redux/store.model';
import { getFeatureToggle } from '../../../featureToggle';
import ListPolices from '../../Incident/Policy/ListPolices';
import { useUserRightUtil } from '../../../util/rights';

export interface StoerkIdDetailsProps {
  workspace?: WorkspaceAPI;
  workspaces?: WorkspaceAPI[];
  requests?: StoerkIdRequestAPI[];
  requestActive?: StoerkIdRequestAPI;
  onChangeWorkspace?: (workspace: WorkspaceAPI) => void;
  showStoerkIDInfo?: boolean;
  showAccountData?: boolean;
  showRequestInfo?: boolean;
  showBillingInfo?: boolean;
  showAutoCharge?: boolean;
  editAutoCharge?: boolean;
  /** even if all users could see theris policies,
   * StoerkSales should not */
  showPolicies?: boolean;
  showRequestWorkspaceActions?: boolean;
  showWorkspaceActions?: boolean;
  isStoerkIdPage?: boolean;
}

function StoerkIdDetails(props: StoerkIdDetailsProps) {
  const {
    workspace,
    requests,
    onChangeWorkspace,
    workspaces = [],
    requestActive,
    showAccountData,
    showBillingInfo,
    showRequestInfo,
    showPolicies,
    showRequestWorkspaceActions,
    showWorkspaceActions,
  } = props;

  const workspaceId = workspace?.id ?? '';
  const featureToggle = useAppSelector(getFeatureToggle);
  const userRightUtil = useUserRightUtil();
  const showWorkspaceInfo = userRightUtil.canViewWorkspace(workspaceId);
  const editWorkspaceInfo = userRightUtil.canUpdateWorkspace(workspaceId);
  const showWorkspaceRequest =
    userRightUtil.canViewWorkspaceRequest(workspaceId);
  const editWorkspaceEditRequest =
    userRightUtil.canUpdateWorkspaceRequest(workspaceId);
  const showUsers = userRightUtil.canViewWorkspaceUsers(workspaceId);
  const showDevices = userRightUtil.canViewWorkspaceDevices(workspaceId);
  const isInRequestProcess = !workspace && !!requestActive;

  return (
    <Box marginY={2}>
      <Stack spacing={2} divider={<hr />}>
        {showWorkspaceInfo && workspaces?.length > 2 ? (
          <SelectWorkspace
            workspaces={workspaces}
            workspace={workspace}
            onChangeWorkspace={onChangeWorkspace}
          />
        ) : null}
        {(showWorkspaceInfo || isInRequestProcess) && (
          <StoerkIdStatus
            workspace={workspace}
            showAccountData={showAccountData}
            showAutoCharge={showWorkspaceRequest && showBillingInfo}
            editAutoCharge={editWorkspaceEditRequest && showBillingInfo}
          />
        )}
        {workspace && showWorkspaceActions ? (
          <CustomerWorkspaceActions workspace={workspace} />
        ) : null}
        {(showWorkspaceRequest || isInRequestProcess) && requestActive ? (
          <StoerkIdRequestStage
            request={requestActive}
            showRequestInfo={showRequestInfo}
            showBillingInfo={showBillingInfo}
            showAutoCharge={showWorkspaceInfo && showBillingInfo}
            editAutoCharge={editWorkspaceInfo && showBillingInfo}
            isLastRequest
          />
        ) : null}

        {requestActive &&
        showRequestWorkspaceActions &&
        editWorkspaceEditRequest ? (
          <CustomerActions request={requestActive} />
        ) : null}
        {workspace && showUsers ? (
          <StoerkIdTeamMembers workspace={workspace} />
        ) : null}
        {workspace && showDevices ? (
          <StoerkIdDevices workspaceId={workspace.id} />
        ) : null}
        {workspace && showPolicies && featureToggle.alarmManager && (
          <ListPolices workspaceId={workspace.id} />
        )}
        {requests?.length && (showWorkspaceRequest || isInRequestProcess) ? (
          <StoerkIdHistory requests={requests} />
        ) : null}
      </Stack>
    </Box>
  );
}

export default StoerkIdDetails;
