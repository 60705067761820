import React from 'react';
import { Typography } from '@mui/material';

export default function StoerkIdPlusTermsAndConditionsText() {
  return (
    <Typography>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras interdum sed
      nibh sit amet accumsan. Curabitur ullamcorper, sapien in viverra vehicula,
      lectus magna sodales libero, a efficitur lorem urna in libero. Suspendisse
      potenti. Mauris egestas, mi quis condimentum malesuada, orci dolor
      consequat orci, eu commodo nunc justo at velit. Phasellus in finibus
      massa. Duis sed felis eu ante auctor tincidunt. Pellentesque habitant
      morbi tristique senectus et netus et malesuada fames ac turpis egestas.
      Praesent id velit vitae eros efficitur vehicula. Pellentesque habitant
      morbi tristique senectus et netus et malesuada fames ac turpis egestas.
      Proin vitae massa nec velit molestie cursus at vel nisl. Sed sit amet nisi
      sed arcu sodales feugiat. Lorem ipsum dolor sit amet, consectetur
      adipiscing elit. Cras interdum sed nibh sit amet accumsan. Curabitur
      ullamcorper, sapien in viverra vehicula, lectus magna sodales libero, a
      efficitur lorem urna in libero. Suspendisse potenti. Mauris egestas, mi
      quis condimentum malesuada, orci dolor consequat orci, eu commodo nunc
      justo at velit. Phasellus in finibus massa. Duis sed felis eu ante auctor
      tincidunt. Pellentesque habitant morbi tristique senectus et netus et
      malesuada fames ac turpis egestas. Praesent id velit vitae eros efficitur
      vehicula. Pellentesque habitant morbi tristique senectus et netus et
      malesuada fames ac turpis egestas. Proin vitae massa nec velit molestie
      cursus at vel nisl. Sed sit amet nisi sed arcu sodales feugiat. Lorem
      ipsum dolor sit amet, consectetur adipiscing elit. Cras interdum sed nibh
      sit amet accumsan. Curabitur ullamcorper, sapien in viverra vehicula,
      lectus magna sodales libero, a efficitur lorem urna in libero. Suspendisse
      potenti. Mauris egestas, mi quis condimentum malesuada, orci dolor
      consequat orci, eu commodo nunc justo at velit. Phasellus in finibus
      massa. Duis sed felis eu ante auctor tincidunt. Pellentesque habitant
      morbi tristique senectus et netus et malesuada fames ac turpis egestas.
      Praesent id velit vitae eros efficitur vehicula. Pellentesque habitant
      morbi tristique senectus et netus et malesuada fames ac turpis egestas.
      Proin vitae massa nec velit molestie cursus at vel nisl. Sed sit amet nisi
      sed arcu sodales feugiat. Lorem ipsum dolor sit amet, consectetur
      adipiscing elit. Cras interdum sed nibh sit amet accumsan. Curabitur
      ullamcorper, sapien in viverra vehicula, lectus magna sodales libero, a
      efficitur lorem urna in libero. Suspendisse potenti. Mauris egestas, mi
      quis condimentum malesuada, orci dolor consequat orci, eu commodo nunc
      justo at velit. Phasellus in finibus massa. Duis sed felis eu ante auctor
      tincidunt. Pellentesque habitant morbi tristique senectus et netus et
      malesuada fames ac turpis egestas. Praesent id velit vitae eros efficitur
      vehicula. Pellentesque habitant morbi tristique senectus et netus et
      malesuada fames ac turpis egestas. Proin vitae massa nec velit molestie
      cursus at vel nisl. Sed sit amet nisi sed arcu sodales feugiat. Lorem
      ipsum dolor sit amet, consectetur adipiscing elit. Cras interdum sed nibh
      sit amet accumsan. Curabitur ullamcorper, sapien in viverra vehicula,
      lectus magna sodales libero, a efficitur lorem urna in libero. Suspendisse
      potenti. Mauris egestas, mi quis condimentum malesuada, orci dolor
      consequat orci, eu commodo nunc justo at velit. Phasellus in finibus
      massa. Duis sed felis eu ante auctor tincidunt. Pellentesque habitant
      morbi tristique senectus et netus et malesuada fames ac turpis egestas.
      Praesent id velit vitae eros efficitur vehicula. Pellentesque habitant
      morbi tristique senectus et netus et malesuada fames ac turpis egestas.
      Proin vitae massa nec velit molestie cursus at vel nisl. Sed sit amet nisi
      sed arcu sodales feugiat. Lorem ipsum dolor sit amet, consectetur
      adipiscing elit. Cras interdum sed nibh sit amet accumsan. Curabitur
      ullamcorper, sapien in viverra vehicula, lectus magna sodales libero, a
      efficitur lorem urna in libero. Suspendisse potenti. Mauris egestas, mi
      quis condimentum malesuada, orci dolor consequat orci, eu commodo nunc
      justo at velit. Phasellus in finibus massa. Duis sed felis eu ante auctor
      tincidunt. Pellentesque habitant morbi tristique senectus et netus et
      malesuada fames ac turpis egestas. Praesent id velit vitae eros efficitur
      vehicula. Pellentesque habitant morbi tristique senectus et netus et
      malesuada fames ac turpis egestas. Proin vitae massa nec velit molestie
      cursus at vel nisl. Sed sit amet nisi sed arcu sodales feugiat. Lorem
      ipsum dolor sit amet, consectetur adipiscing elit. Cras interdum sed nibh
      sit amet accumsan. Curabitur ullamcorper, sapien in viverra vehicula,
      lectus magna sodales libero, a efficitur lorem urna in libero. Suspendisse
      potenti. Mauris egestas, mi quis condimentum malesuada, orci dolor
      consequat orci, eu commodo nunc justo at velit. Phasellus in finibus
      massa. Duis sed felis eu ante auctor tincidunt. Pellentesque habitant
      morbi tristique senectus et netus et malesuada fames ac turpis egestas.
      Praesent id velit vitae eros efficitur vehicula. Pellentesque habitant
      morbi tristique senectus et netus et malesuada fames ac turpis egestas.
      Proin vitae massa nec velit molestie cursus at vel nisl. Sed sit amet nisi
      sed arcu sodales feugiat. Lorem ipsum dolor sit amet, consectetur
      adipiscing elit. Cras interdum sed nibh sit amet accumsan. Curabitur
      ullamcorper, sapien in viverra vehicula, lectus magna sodales libero, a
      efficitur lorem urna in libero. Suspendisse potenti. Mauris egestas, mi
      quis condimentum malesuada, orci dolor consequat orci, eu commodo nunc
      justo at velit. Phasellus in finibus massa. Duis sed felis eu ante auctor
      tincidunt. Pellentesque habitant morbi tristique senectus et netus et
      malesuada fames ac turpis egestas. Praesent id velit vitae eros efficitur
      vehicula. Pellentesque habitant morbi tristique senectus et netus et
      malesuada fames ac turpis egestas. Proin vitae massa nec velit molestie
      cursus at vel nisl. Sed sit amet nisi sed arcu sodales feugiat.
    </Typography>
  );
}
