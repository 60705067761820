import React, { useMemo } from 'react';

import { getPolyglot } from '../../../i18n';
import { ButtonIcon } from '../../../theme/components';
import { AlarmIcon } from '../../../theme/icons';
import {
  CircularProgress,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Stack,
  badgeClasses,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useWatchError from '../../../handlingErrors/useWatchError';
import AlarmMenuItem from './AlarmMenuItem';
import IncidentAPI from '../../../redux/incident/api/IncidentAPI';
import { useAppSelector } from '../../../redux/store.model';
import { getMyWorkspaceSelector } from '../../../redux/stoerkID/selectors/StoerkId.selectors';
import { INCIDENTS_POLLING_INTERVAL } from '../../../redux/incident/api/Incident.model';

export default function AlarmBox() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const navigate = useNavigate();
  const polyglot = getPolyglot();

  const workspace = useAppSelector(getMyWorkspaceSelector);
  const params = useMemo(
    () => ({ workspaceId: workspace?.id, filterByActive: true }),
    [workspace]
  );
  const {
    error: errorIncidents,
    isLoading: isLoadingIncidents,
    isFetching: isFetchingIncidents,
    data: incidentsData = [],
  } = IncidentAPI.useGetMyIncidentByWorkspaceIdQuery(params, {
    refetchOnMountOrArgChange: true,
    skip: !workspace,
    pollingInterval: INCIDENTS_POLLING_INTERVAL,
  });
  const activeIncidents = useMemo(
    () => incidentsData.filter((alarm) => !alarm.resolutionTimestamp),
    [incidentsData]
  );

  const onClickShowAll = () => {
    navigate('/alarms');
  };
  const handleClickOpen = (event: React.MouseEvent<HTMLElement>) => {
    if (activeIncidents.length !== 0) setAnchorEl(event.currentTarget);
    else onClickShowAll();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isFetching = isFetchingIncidents;
  const isLoading = isLoadingIncidents;
  const error = errorIncidents;

  useWatchError(error);

  const trigger = (
    <ButtonIcon
      badge={
        isLoading || isFetching ? (
          <CircularProgress sx={{ color: 'white' }} size={15} />
        ) : (
          activeIncidents.length
        )
      }
      onClick={handleClickOpen}
      sx={(theme) => ({
        fontSize: '2.2em',
        [theme.breakpoints.up('lg')]: {
          fontSize: '3em',
        },

        [`& .${badgeClasses.badge}`]: {
          fontSize: '0.33em',
          height: '3em',
          width: '3em',
          top: '10px',
          backgroundColor: 'error.main',
        },
      })}
    >
      <AlarmIcon height={'1em'} />
    </ButtonIcon>
  );

  return (
    <Stack>
      {trigger}
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'lock-button',
          role: 'listbox',
        }}
      >
        <MenuList
          onClick={handleClose}
          sx={{ width: '100%', maxWidth: 360 }}
          disablePadding
        >
          <MenuItem onClick={onClickShowAll} color="primary">
            <ListItemText
              primary={polyglot.t('incident.all_alarms')}
              primaryTypographyProps={{
                variant: 'subtitle2',
                textAlign: 'center',
                color: 'primary',
              }}
            />
          </MenuItem>
          {activeIncidents.slice(0, 10).map((incident) => (
            <AlarmMenuItem key={incident.uuid} incident={incident} />
          ))}
        </MenuList>
      </Menu>
    </Stack>
  );
}
