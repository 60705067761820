import React from 'react';
import { useParams } from 'react-router-dom';
import { QueryParamControlUnitPage } from '../CUPage.model';
import './ControlUnitDetailPage.scss';
import Actions from './Actions';
import MainSensor from './MainSensor/MainSensor';
import Sensors from './Sensors';
import SetPoints from './SetPoints';
import Status from './Status';
import withHandlingErrors, {
  HandlingErrorWrappedProps,
} from '../../../../../handlingErrors';
import { useCurrentStateByControlUnitId } from '../../../../../redux/controlUnit/hooks/useCurrentStateByControlUnitId';
import { Box, CircularProgress } from '@mui/material';
import _ from 'lodash';

type ControlUnitDetailPageProps = HandlingErrorWrappedProps;
export function ControlUnitDetailPage(props: ControlUnitDetailPageProps) {
  const { handlingErrorsApi } = props;
  const params = useParams<QueryParamControlUnitPage>();
  const { deviceId = '', controlUnitId = '' } = params;

  const {
    loading,
    controlUnit,
    currentAlarms,
    currentSensorData,
    setPoints,
    hasAlarms,
    actions,
    status,
    isOn,
    cloudConnectivity,
    mainSensor,
    color,
    CUTranslate,
  } = useCurrentStateByControlUnitId(
    deviceId,
    controlUnitId,
    handlingErrorsApi
  );

  const numStatus = _.sum(Object.values(status).map((status) => status.length));
  const isStatusPresent = numStatus > 0;
  const istSetPointsPresent = !!setPoints?.length;
  const dense =
    isStatusPresent &&
    istSetPointsPresent &&
    (numStatus > 4 || setPoints?.length > 2);

  if (loading)
    return (
      loading && (
        <Box textAlign={'center'}>
          <CircularProgress />
        </Box>
      )
    );

  return (
    <Box
      className={`ControlUnitDetailPage-container ${
        !isStatusPresent ? 'no-status' : ''
      } ${!istSetPointsPresent ? 'no-set-points' : ''} ${dense ? 'dense' : ''}`}
      sx={{
        backgroundColor: (theme) => theme.palette.background.default,
        padding: 1,
      }}
    >
      <MainSensor
        mainSensor={mainSensor}
        isOn={isOn}
        isConnected={cloudConnectivity}
        currentAlarms={currentAlarms}
        hasAlarms={hasAlarms}
        CUTranslate={CUTranslate}
        color={color}
      />
      <SetPoints
        setPoints={setPoints}
        controlUnit={controlUnit}
        CUTranslate={CUTranslate}
      />
      <Sensors
        sensorsData={currentSensorData}
        isOn={isOn}
        isConnected={cloudConnectivity}
        CUTranslate={CUTranslate}
      />
      <Status CUTranslate={CUTranslate} status={status} />
      <Actions actions={actions} CUTranslate={CUTranslate} />
    </Box>
  );
}

export default withHandlingErrors(ControlUnitDetailPage);
