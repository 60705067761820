import { createAsyncThunk } from '@reduxjs/toolkit';
import CUParametersCalls from '../../../redux/CUParameters/api/CUParametersCalls';
import { ErrorRestST } from '../../../axios/rest.model';
import { nameReducer } from '../CUParameters.model';
import exponentialPollingPromise from '../../../util/promise/exponentialPollingPromise';
import { CUParametersObjAPIResponse } from '../api/CUParameters.model';
import { ComposedParametersValue } from '../selectors';
import { getValue } from '../api/util/parameterUtil';

// ------------------------------------
// Thunks created with @reduxjs/toolkit
// ------------------------------------

export const fetchCUParameters = createAsyncThunk(
  `${nameReducer}/fetchCUParameters`,
  async (props: { deviceId: string; controlUnitId: number | string }) => {
    const { controlUnitId, deviceId } = props;
    const cUParametersCalls = new CUParametersCalls();

    return await cUParametersCalls.getCUParametersFromCU(
      deviceId,
      controlUnitId
    );
  },
  // It is necessary because front need ErrorRestST['errorId']
  { serializeError: (e: unknown) => e as ErrorRestST }
);

export const setCUParameters = createAsyncThunk(
  `${nameReducer}/setCUParameters`,
  async (
    props: {
      deviceId: string;
      controlUnitId: number | string;
      parameterLevelName: string;
      parameter: ComposedParametersValue;
      parameterValue: string;
    },
    thinkAPI
  ) => {
    const {
      controlUnitId,
      deviceId,
      parameterLevelName,
      parameter,
      parameterValue,
    } = props;
    const cUParametersCalls = new CUParametersCalls();
    const parameterName = parameter.name;

    const mutationResponse = await cUParametersCalls.setCUParameters(
      deviceId,
      controlUnitId,
      parameterLevelName,
      parameterName,
      parameterValue
    );

    const getActionStatusResponsePromise = () =>
      cUParametersCalls.getCUParametersFromCU(deviceId, controlUnitId);
    const isActionStatusEqual = (value: CUParametersObjAPIResponse) => {
      const valueIncoming = value.parameters[parameterLevelName].find(
        (parameter) => parameter.name === parameterName
      )?.value;
      const currentValue = getValue({ ...parameter, value: valueIncoming });
      const desiredValue = getValue({ ...parameter, value: parameterValue });
      return currentValue === desiredValue;
    };
    return exponentialPollingPromise(
      getActionStatusResponsePromise,
      isActionStatusEqual
    )
      .then(() => mutationResponse)
      .finally(() =>
        thinkAPI.dispatch(fetchCUParameters({ deviceId, controlUnitId }))
      );
  },
  // It is necessary because front need ErrorRestST['errorId']
  { serializeError: (e: unknown) => e as ErrorRestST }
);
