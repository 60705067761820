import { createSelector } from '@reduxjs/toolkit';
import { Group } from '../api/group.model';
import { getRightUserUtilSelector } from '../../rights/selectors';
import { RootState } from '../../store.model';

const getGroupIdFromProps = (_: any, props: { group?: Group }) => props.group;

export const groupButtonsRightsSelector = createSelector(
  [
    getGroupIdFromProps,
    getRightUserUtilSelector,
    (state: RootState) => state.featureToggle,
  ],
  (group, rightsUserUtil, featureToggle) => {
    const groupId = group?.id ?? '';
    const showButtonReadIncidents = !!rightsUserUtil.hasRightsToReadIncident(
      null,
      groupId
    );
    const showButtonUpdateGroup =
      !!rightsUserUtil.hasRightsToUpdateGroup(groupId);
    const showButtonDeleteGroup =
      !!rightsUserUtil.hasRightsToDeleteGroup(groupId);
    const showButtonRecipeGroup = !!(
      featureToggle.recipeManager &&
      rightsUserUtil.hasRightsToReadRecipe(null, groupId)
    );
    const showButtonCameraGroups = !!featureToggle.camera;
    const contacts = group?.attributes?.contacts ?? [];
    const showButtonContacts = contacts.length > 0;
    const showButtonHistory = rightsUserUtil.hasRightsToReadHistory();

    return {
      showButtonReadIncidents,
      showButtonUpdateGroup,
      showButtonDeleteGroup,
      showButtonRecipeGroup,
      showButtonCameraGroups,
      showButtonContacts,
      showButtonHistory,
    };
  }
);
