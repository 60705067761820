import React from 'react';
import { ButtonIcon } from '../../../../../../theme/components';
import { ChartIcon } from '../../../../../../theme/icons';
import type { ControlUnitCardListProps } from '../ControlUnitCardList';

const iconId = 'chart';
export function ControlUnitHistoryAction(props: ControlUnitCardListProps) {
  const {
    polyglot,
    controlUnitId,
    groupId,
    deviceId,
    navigate,
    moduleSelected,
    showButtonHistory,
  } = props;
  if (!showButtonHistory) return null;
  const selected = moduleSelected === 'history';

  const openControlUnitHistory = (e: React.MouseEvent) => {
    if (selected) return;
    let url = `/devicemanager/device/${deviceId}/device-detail/control-unit/${controlUnitId}/history`;
    if (groupId) {
      url = `/devicemanager/device/${deviceId}/group/${groupId}/device-detail/control-unit/${controlUnitId}/history`;
    }
    navigate(url);
  };

  const tooltipContent = polyglot.t('device.monitoring.title');

  return (
    <ButtonIcon
      className={`icon-${iconId} controlUnitId-${controlUnitId}`}
      tooltip={tooltipContent}
      onClick={openControlUnitHistory}
    >
      {<ChartIcon />}
    </ButtonIcon>
  );
}
