import { Box, Grid, InputAdornment, Slider, TextField } from '@mui/material';
import React from 'react';
import { ParameterEditControlProps } from './ParameterEditControl';

export default function ParameterEditControlSlider(
  props: ParameterEditControlProps
) {
  const { parameter, onChangeValue, value, polyglot } = props;
  const numberValue = parseFloat(value);

  function formatValue(value: number) {
    return `${value.toFixed(parameter.decimals ?? 2)}${
      parameter.unitString ?? ''
    }`;
  }
  const onChangeSlider = (event: Event, value: number | number[]) => {
    onChangeValue(value.toString());
  };
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value === '' ? 0 : Number(event.target.value);
    onChangeValue(value.toString());
  };

  const handleBlur = () => {
    if (numberValue < parameter.min) {
      onChangeValue(parameter.min.toString());
    } else if (numberValue > parameter.max) {
      onChangeValue(parameter.max.toString());
    }
  };

  const step = 1 / 10 ** parameter.decimals;
  const marks = [
    {
      value: parameter.min,
      label: formatValue(parameter.min),
    },
    {
      value: parameter.max,
      label: formatValue(parameter.max),
    },
  ];
  return (
    <Box
      className="ParameterEditControlSlider"
      sx={{ width: '100%' }}
      padding={2}
    >
      <Grid container alignItems="center">
        <Grid item xs={12} alignItems="center" textAlign={'center'}>
          <TextField
            value={value}
            size="small"
            onChange={handleInputChange}
            onBlur={handleBlur}
            required
            InputProps={{
              endAdornment: parameter.unitString && (
                <InputAdornment position="end">
                  {parameter.unitString}
                </InputAdornment>
              ),
            }}
            inputProps={{
              step: step,
              min: parameter.min,
              max: parameter.max,
              type: 'number',
              'aria-labelledby': 'input-slider',
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Slider
            aria-label={polyglot.t('device.parameters.values')}
            value={numberValue}
            onChange={onChangeSlider}
            getAriaValueText={formatValue}
            step={step}
            marks={marks}
            min={parameter.min}
            max={parameter.max}
            valueLabelDisplay="auto"
          />
        </Grid>
      </Grid>
    </Box>
  );
}
