import { Button, Stack } from '@mui/material';
import React from 'react';
import { getPolyglot } from '../../../i18n';
import { Link as LinkRouter } from 'react-router-dom';
import {
  StoerkIdRequestAPI,
  WorkspaceAPI,
} from '../../../redux/stoerkID/StoerkIdAPI.model';
import StoerkIdAPI from '../../../redux/stoerkID/StoerkIdAPI';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import { useUserRightUtil } from '../../../util/rights';
import useWatchError from '../../../handlingErrors/useWatchError';

interface MyStoerkIdActionsProps {
  workspace?: WorkspaceAPI;
  request?: StoerkIdRequestAPI;
}
export default function MyStoerkIdActions(props: MyStoerkIdActionsProps) {
  const { workspace, request } = props;
  const polyglot = getPolyglot();
  const userRightUtil = useUserRightUtil();
  const showStripePortal = userRightUtil.canAccessStripeWorkspace(
    workspace?.id ?? ''
  );

  return (
    <Stack spacing={1}>
      {!!(showStripePortal || request?.stripeUserId) && (
        <StripePortalButtton {...props} />
      )}
      {!workspace && (
        <Button
          size="large"
          variant="outlined"
          to="/stoerk-id/request?action=edit"
          component={LinkRouter}
        >
          {polyglot.t('stoerk_id.detail_page.edit_stoerk_id_button')}
        </Button>
      )}
    </Stack>
  );
}

const StripePortalButtton = (props: MyStoerkIdActionsProps) => {
  const { workspace, request } = props;
  const polyglot = getPolyglot();
  const {
    data: stripeInfo,
    isLoading: isLoadingStripeInfo,
    error: errorStripeInfo,
  } = StoerkIdAPI.useGetWorkspaceStripeInfoQuery(workspace?.id, {
    skip: !workspace?.id,
  });

  const [
    fetchStripePortal,
    { isFetching: isLoadingStripePortal, error: errorStripePortal },
  ] = StoerkIdAPI.useLazyGetStripePortalQuery();

  const error = errorStripeInfo || errorStripePortal;
  const isLoading = isLoadingStripeInfo || isLoadingStripePortal;
  useWatchError(error);

  const onClick = () => {
    fetchStripePortal(
      {
        workspaceId: workspace?.id,
        requestId: request?.requestId,
      },
      false
    ).then((response) => {
      window.open(response?.data?.url, '_blank');
    });
  };

  const stripeId = stripeInfo?.id || request?.stripeUserId;

  return stripeId ? (
    <LoadingButton
      size="large"
      onClick={onClick}
      loading={isLoading}
      variant="contained"
    >
      {polyglot.t('stoerk_id.detail_page.stripe_portal_button')}
    </LoadingButton>
  ) : null;
};
