import { Paper } from '@mui/material';
import React, { useState } from 'react';
import { QueryParamControlUnitPage } from '../../CUPage.model';
import { ParameterEdit } from './ParameterEdit';
import { ParameterView } from './ParameterView';
import { PolyglotComponentProps, withPolyglot } from '../../../../../../i18n';
import withHandlingErrors, {
  HandlingErrorWrappedProps,
} from '../../../../../../handlingErrors';
import { ComposedParametersValue } from '../../../../../../redux/CUParameters/selectors';
import { useAppDispatch } from '../../../../../../redux/store.model';
import { setCUParameters } from '../../../../../../redux/CUParameters/action/thunks';
import { useShowMessage } from '../../../../../../util/hooks';

export interface ParameterProps
  extends QueryParamControlUnitPage,
    PolyglotComponentProps,
    HandlingErrorWrappedProps {
  parameter: ComposedParametersValue;
  parameterLevelId: string;
}

function Parameter(props: ParameterProps) {
  const {
    deviceId = '',
    controlUnitId = '',
    parameterLevelId,
    polyglot,
    handlingErrorsApi,
  } = props;
  const [showEdit, setShowEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const onClickOpenEdit = () => setShowEdit(true);
  const onClickCloseEdit = () => setShowEdit(false);
  const dispatch = useAppDispatch();
  const showMessage = useShowMessage();
  const onSave = (parameter: ComposedParametersValue, newValue: string) => {
    setLoading(true);
    dispatch(
      setCUParameters({
        deviceId,
        controlUnitId,
        parameterLevelName: parameterLevelId,
        parameter: parameter,
        parameterValue: newValue,
      })
    )
      .unwrap()
      .then(() => {
        showMessage(polyglot.t('device.parameters.save_value_successfully'));
        onClickCloseEdit();
      })
      .catch(handlingErrorsApi)
      .finally(() => setLoading(false));
  };
  return showEdit ? (
    <ParameterEdit
      {...props}
      loading={loading}
      onClickClose={onClickCloseEdit}
      onSave={onSave}
    />
  ) : (
    <ParameterView {...props} onClickEdit={onClickOpenEdit} />
  );
}

export default withHandlingErrors(withPolyglot(Parameter));
