import React from 'react';
// import SetPointEdit from './SetPointEdit';
import { PolyglotComponentProps, withPolyglot } from '../../../../../../i18n';
import { ControlUnitAPIResponse } from '../../../../../../redux/controlUnit/api/ControlUnit.model';
import { useCurrentStateByControlUnitId } from '../../../../../../redux/controlUnit/hooks/useCurrentStateByControlUnitId';
import {
  DEFAULT_DECIMALS,
  customSetPoints,
} from '../../../../../../redux/controlUnit/api/util/constants';
import { getSetPointName } from '../../../../../../redux/controlUnit/api/util/setPoint/getSetPointName';
import {
  DeviceControlSetPointsMetadataAPIResponse,
  DeviceControlUnitsChannelMetadataAPIResponse,
} from '../../../../../../redux/devices/api/device.model';
import {
  DigitalChannelEventMixed,
  SensordataNewest,
} from '../../../../../../redux/monitoring/api/monitoring.model';
import SingleDataSetPoint from './SingleDataSetPoint';
import { Box, Button, Paper, Popover, Typography } from '@mui/material';

export interface SetPointProps extends PolyglotComponentProps {
  setPoint: DeviceControlSetPointsMetadataAPIResponse;
  sensorData?: SensordataNewest | undefined;
  controlUnit: ControlUnitAPIResponse;
  objDebug?: {
    setPoint: DeviceControlSetPointsMetadataAPIResponse;
    action: DigitalChannelEventMixed | undefined;
    channel: DeviceControlUnitsChannelMetadataAPIResponse | undefined;
    sensorData: SensordataNewest | undefined;
  };
  CUTranslate: ReturnType<typeof useCurrentStateByControlUnitId>['CUTranslate'];
}
export function SetPoint(props: SetPointProps) {
  const { setPoint, sensorData, controlUnit, CUTranslate, polyglot } = props;

  // const [showEditSetPoint, setShowEditSetPoint] = useState(false);
  // const openEditSetPoint = () => setShowEditSetPoint(true);
  // const onCloseEditSetPoint = () => setShowEditSetPoint(false);

  const setPointDec = setPoint?.dec ?? DEFAULT_DECIMALS;
  const setPointUnit = setPoint?.unitString ?? '';
  const setPointValue = sensorData?.value
    ? sensorData?.value.toFixed(setPointDec)
    : undefined;
  const setPointMinValue = setPoint?.min.toFixed(setPointDec);
  const setPointMaxValue = setPoint?.max.toFixed(setPointDec);

  const setPointButton =
    customSetPoints[controlUnit.version.progNum]?.[setPoint.name ?? ''];

  const titleSetPoint = getSetPointName(
    sensorData?.channel ?? '',
    CUTranslate(setPoint.name ?? '') as string,
    polyglot,
    setPointButton,
    setPointMinValue,
    setPointMaxValue,
    setPointUnit
  );
  // FIXME when write action are available
  // if (showEditSetPoint) return <SetPointEdit {...props} />;

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <SingleDataSetPoint
        name={titleSetPoint}
        value={`${setPointValue !== undefined ? setPointValue : '---'}`}
        onClickUpdate={() => {
          alert('WIP: action not supported');
        }}
        unitValue={setPointUnit}
      />
      <Button aria-describedby={id} variant="contained" onClick={handleClick}>
        Debug info
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography fontWeight={'bold'}>Debug info:</Typography>
          <Typography>SetPoint.id: {setPoint.id}</Typography>
          <Typography>
            SetPoint.action: {JSON.stringify(props.objDebug?.action)}
          </Typography>
          <Typography>
            SetPoint.sensorData: {JSON.stringify(props.objDebug?.sensorData)}
          </Typography>
          <Typography>
            SetPoint.channel: {JSON.stringify(props.objDebug?.channel)}
          </Typography>
          <Typography>
            SetPoint.setPoint: {JSON.stringify(props.objDebug?.setPoint)}
          </Typography>
        </Box>
      </Popover>
    </>
  );
}

export default withPolyglot(SetPoint);
