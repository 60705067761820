import React from 'react';
import { ControlUnitAPIResponse } from '../../../../../../redux/controlUnit/api/ControlUnit.model';
import { useCurrentStateByControlUnitId } from '../../../../../../redux/controlUnit/hooks/useCurrentStateByControlUnitId';
import SetPoint from './SetPoint';

interface SetPointsProps {
  controlUnit: ControlUnitAPIResponse | undefined;
  setPoints: ReturnType<typeof useCurrentStateByControlUnitId>['setPoints'];
  CUTranslate: ReturnType<typeof useCurrentStateByControlUnitId>['CUTranslate'];
}
export default function SetPoints(props: SetPointsProps) {
  const { controlUnit, setPoints } = props;
  return controlUnit && setPoints?.length ? (
    <div className="ControlUnit-SetPoints-container">
      {setPoints.map((setPoint) => (
        <SetPoint
          key={setPoint.setPoint.id}
          {...props}
          setPoint={setPoint.setPoint}
          sensorData={setPoint.sensorData}
          controlUnit={controlUnit}
          objDebug={setPoint}
        />
      ))}
    </div>
  ) : null;
}
