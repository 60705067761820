import {
  Box,
  CircularProgress,
  Grid,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import React from 'react';
import { getPolyglot } from '../../../../i18n';
import { WorkspaceAPI } from '../../../../redux/stoerkID/StoerkIdAPI.model';
import useWatchError from '../../../../handlingErrors/useWatchError';
import StoerkIdAPI from '../../../../redux/stoerkID/StoerkIdAPI';
import AdditionalContactInfo from './AdditionalContactInfo';
import StoerkIdAutoCharge from './StoerkIdAutoCharge';

export interface StoerkIdDetailsProps {
  workspace?: WorkspaceAPI;
  showAccountData?: boolean;
  showAutoCharge?: boolean;
  editAutoCharge?: boolean;
}
export function StoerkIdStatus(props: StoerkIdDetailsProps) {
  const { workspace, showAccountData } = props;

  const polyglot = getPolyglot();
  const theme = useTheme();
  return (
    <Box
      sx={{
        '& .StcTableCell-head': {
          fontWeight: 'bold',
        },
        '& table': {
          width: '100%',
          textAlign: 'left',
          '& .StcTableRow-head': {
            ...theme.typography.h3,
            fontSize: '0.9rem',
          },
          '& .StcTableBody-root:before': {
            lineHeight: '0.5em',
            content: '"-"',
            display: 'block',
            color: 'transparent',
          },
          '& .StcTableCell-body': {
            ...theme.typography.body1,
            fontSize: '0.8rem',
          },
        },
      }}
    >
      <Typography variant="h2" sx={{ marginBottom: 2 }}>
        {polyglot.t('stoerk_id.detail_page.status.subtitle')}
      </Typography>

      {workspace ? (
        <TableActive workspace={workspace} showAccountData={showAccountData} />
      ) : (
        <TableInactive />
      )}
    </Box>
  );
}

export interface TableActivesProps {
  workspace: WorkspaceAPI;
  billingInfo?: any;
  showAccountData?: boolean;
}

const TitleTypography = styled(Typography)(({ theme }) => ({
  ...theme.typography.h3,
  fontSize: '0.9rem',
  marginBottom: '0.5rem',
}));

const TableActive = (props: TableActivesProps) => {
  const { workspace, showAccountData } = props;
  const polyglot = getPolyglot();

  const {
    data: billingInfo,
    isLoading: isLoadingBillingInfo,
    error: errorBillingInfo,
  } = StoerkIdAPI.useGetBillingInfoFromWorkspaceQuery(workspace?.id);

  const {
    data: workspaceUsage,
    isLoading: isLoadingWorkspaceUsage,
    error: errorWorkspaceUsage,
  } = StoerkIdAPI.useGetWorkspaceUsageQuery(workspace?.id);
  const error = errorBillingInfo || errorWorkspaceUsage;
  useWatchError(error);

  return (
    <Grid container spacing={1}>
      <Grid item xs={4} md={4}>
        <TitleTypography>
          {polyglot.t('stoerk_id.detail_page.status.status_header_cell')}
        </TitleTypography>
        <Typography variant="body2">
          {polyglot.t('stoerk_id.detail_page.status.status_active')}
        </Typography>
      </Grid>
      <Grid item xs={4} md={4}>
        <TitleTypography>
          {polyglot.t('stoerk_id.detail_page.status.company_name_header_cell')}
        </TitleTypography>
        <Typography variant="body2">{workspace.name || '-'}</Typography>
      </Grid>

      <Grid item xs={4} md={2}>
        <TitleTypography>
          {polyglot.t('stoerk_id.detail_page.status.commanders_header_cell')}
        </TitleTypography>
        <Typography variant="body2">
          {isLoadingWorkspaceUsage ? (
            <CircularProgress size={'1em'} />
          ) : (
            `${workspaceUsage?.commanders?.length} / ${workspace?.quota.devices}`
          )}
        </Typography>
      </Grid>
      <Grid item xs={4} md={2}>
        <TitleTypography>
          {polyglot.t('stoerk_id.detail_page.status.users_header_cell')}
        </TitleTypography>
        <Typography variant="body2">{`${workspaceUsage?.users?.length} / ${workspace?.quota.users}`}</Typography>
      </Grid>

      {(billingInfo?.pricingDetails?.text || isLoadingBillingInfo) && (
        <Grid item xs={12}>
          <TitleTypography>
            {polyglot.t('stoerk_id.detail_page.status.price_header_cell')}
          </TitleTypography>

          {isLoadingBillingInfo ? (
            <CircularProgress size={'1em'} />
          ) : billingInfo?.pricingDetails?.text ? (
            <Typography variant="body2">
              {billingInfo?.pricingDetails?.text ?? '--'}
            </Typography>
          ) : null}
        </Grid>
      )}

      {showAccountData && <AdditionalContactInfo workspace={workspace} />}

      <StoerkIdAutoCharge workspace={workspace} showAutoCharge editAutoCharge />

      {/*
      <Grid item xs={4} md={3}>
        <TitleTypography>
          {polyglot.t('stoerk_id.detail_page.status.invoice_header_cell')}
        </TitleTypography>
        <Typography variant="body2">06/02/2023 (Mock)</Typography>
      </Grid> */}
    </Grid>
  );
};

const TableInactive = () => {
  const polyglot = getPolyglot();

  return (
    <Grid container spacing={1}>
      <Grid item xs={4} md={4}>
        <TitleTypography>
          {polyglot.t('stoerk_id.detail_page.status.status_header_cell')}
        </TitleTypography>
        <Typography variant="body2">
          {polyglot.t('stoerk_id.detail_page.status.status_inactive')}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default StoerkIdStatus;
