import { createSelector } from '@reduxjs/toolkit';
import StoerkIdAPI from '../StoerkIdAPI';
import { StoerkIdRequestAPI, WorkspaceAPI } from '../StoerkIdAPI.model';
import { RootState } from '../../store.model';
import UserAPI from '../../users/api/UserAPI';
import { isNotNullOrUndefined } from '../../../util/types/Array';
import _, { first } from 'lodash';
import {
  WorkspaceRequestIdParams,
  N_DEVICE_FREE_VERSION,
} from '../../../components/StoerkId/util/StoerkId.constants';
import { getFeatureToggle } from '../../../featureToggle';
import { getAllDevicesIds } from '../../devices/selectors';
import { getDevicesFromGroupSelectedByQueryParamSelector } from '../../groups/selectors';
import { IUser } from '../../users/api/user.model';
import { ErrorRestST } from '../../../axios/rest.model';
export interface ItemsWorkspaceRequestWrapped
  extends Partial<StoerkIdRequestAPI>,
    Partial<WorkspaceAPI> {
  requests: StoerkIdRequestAPI[];
  lastRequest?: StoerkIdRequestAPI;
  workspace?: WorkspaceAPI;
}

/**
 * Get workspaces created with the possible requests
 * or Requests group by creatorId
 */
export const getAccountsByWorkspace = createSelector(
  [
    StoerkIdAPI.endpoints.getSalesTeamRequests.select(undefined),
    StoerkIdAPI.endpoints.getAllWorkspace.select(undefined),
  ],
  (requestResponse, workspaceResponse) => {
    const workspaces = workspaceResponse.data ?? [];
    const requestsData = requestResponse.data ?? [];

    // Need to show without workspace and collapsed by user
    const listRequestNotInWorkspace = requestResponse.data?.filter(
      (request) =>
        !workspaces.find((workspace) => workspace.id === request.workspaceId)
    );
    const workspaceToShow = workspaces.map((workspace) => {
      const requests = requestsData.filter(
        (request) => request.workspaceId === workspace.id
      );

      return {
        ...workspace,
        workspace: workspace,
        creatorId: _.last(requests)?.creatorId ?? workspace.users[0],
        requests,
        lastRequest: _.first(requests),
      };
    });

    const listRequestByCreatorID = _.unionBy(
      listRequestNotInWorkspace,
      'creatorId'
    ).map((request) => {
      const requests = requestsData.filter(
        (req) => req.creatorId === request.creatorId
      );

      return {
        ...request,
        creatorId: request.creatorId,
        requests: requests,
        lastRequest: request,
      };
    });

    return [
      ...workspaceToShow,
      ...listRequestByCreatorID,
    ] as ItemsWorkspaceRequestWrapped[];
  }
);

export const getUsersFromMyWorkspaces = (state: RootState) => {
  const { data: workspaces } =
    StoerkIdAPI.endpoints.getMyWorkspaces.select(undefined)(state);
  return (
    workspaces?.flatMap((workspace) =>
      workspace?.users
        .map((userId) => UserAPI.endpoints.getUser.select(userId)(state).data)
        .filter(isNotNullOrUndefined)
    ) ?? []
  );
};

export const getUsersResponseFromMyWorkspace = (state: RootState) => {
  const myWorkspace = getMyWorkspaceSelector(state);
  /**
   * reduce user workspaces
   * data: as a list of user
   * isLoading: if one of the user is loading
   * error: if one of the user is in error
   */
  return (myWorkspace?.users ?? []).reduce(
    (acc, userId) => {
      const userRespond = UserAPI.endpoints.getUser.select(userId)(state);
      if (userRespond?.data) {
        acc.data.users.push(userRespond.data);
      }
      acc.isLoading = acc?.isLoading ?? userRespond?.isLoading;
      acc.error = acc.error ?? userRespond.error;
      return acc;
    },
    {
      data: { users: [] as IUser[], workspace: myWorkspace },
      isLoading: false,
      error: undefined as ErrorRestST | undefined | unknown,
    }
  );
};

export const getWorkspacesFromUserId = createSelector(
  [
    StoerkIdAPI.endpoints.getAllWorkspace.select(undefined),
    (state: RootState, { requestCreatorId = '' }: WorkspaceRequestIdParams) =>
      requestCreatorId,
  ],
  (workspaces, userId) =>
    workspaces.data?.filter((w) => w.users.includes(userId))
);

/** At the moment we only support one workspace */
export const getMyWorkspaceSelector = createSelector(
  [StoerkIdAPI.endpoints.getMyWorkspaces.select(undefined)],
  (workspacesResponse) => first(workspacesResponse.data)
);
export const getMyDeviceQuota = createSelector(
  [getMyWorkspaceSelector],
  (workspace) => workspace?.quota.devices ?? 0
);

/**
 * Has n devices restricted if:
 * - only if the feature toggle is active
 * - I not have workspace and, then N_DEVICE_FREE_VERSION
 * - if i have workspace use the quota as a restriction
 *
 */
export const nDevicesRestrictedSelector = createSelector(
  [getMyWorkspaceSelector],
  (workspace) => {
    // The quota is used as a restriction
    if (!!workspace && typeof workspace.quota.devices == 'number')
      return workspace.quota.devices;
    return N_DEVICE_FREE_VERSION;
  }
);

/**
 * Has devices restricted if:
 * - only if the feature toggle is active
 * - I not have workspace and, I have more devices than N_DEVICE_FREE_VERSION
 *
 */
export const hasDevicesRestrictedSelector = createSelector(
  [
    getAllDevicesIds,
    (state) => getFeatureToggle(state).stoerkIdRestrictions,
    (state) => getFeatureToggle(state).stoerkId,
    nDevicesRestrictedSelector,
  ],
  (
    allDevicesIds,
    stoerkIdRestrictions,
    stoerkIdEnabled,
    nDevicesRestricted
  ) => {
    if (!stoerkIdRestrictions || !stoerkIdEnabled) return false;
    const limitOverflowDevices = allDevicesIds.length > nDevicesRestricted;
    return limitOverflowDevices;
  }
);

/**
 * Use getDevicesFromGroupSelectedByQueryParamSelector and then
 * remove devices that are not in the N_DEVICE_FREE_VERSION first devices
 * of all devices, those are not restricted devices
 */
export const getDevicesNotRestrictedByQueryParamSelector = createSelector(
  [
    getDevicesFromGroupSelectedByQueryParamSelector,
    getAllDevicesIds,
    hasDevicesRestrictedSelector,
    nDevicesRestrictedSelector,
  ],
  (devices, allDevicesIds, hasDevicesRestricted, nDevicesRestricted) => {
    if (!hasDevicesRestricted) return devices;
    const deviceIdsNotRestricted = allDevicesIds.slice(0, nDevicesRestricted);

    return devices.filter((device) =>
      deviceIdsNotRestricted.includes(device.uuid)
    );
  }
);

export const getWorkspaceQuotaUsageSelector = (workspaceId: string) =>
  StoerkIdAPI.endpoints.getWorkspaceUsage.select(workspaceId);
