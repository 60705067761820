import React, { useState } from 'react';
import InfoModal from '../../../theme/components/Modal/InfoModal/InfoModal';
import { Box, Typography } from '@mui/material';
import { getPolyglot } from '../../../i18n';
import { SuccessIcon } from '../../../theme/icons';

export default function StoerkIdRequestSuccesufullModal() {
  const polyglot = getPolyglot();
  const queryParams = new URLSearchParams(window.location.search);
  const action = queryParams.get('action');

  const [showSuccesModal, setShowSuccesModal] = useState(
    action === 'success-request'
  );
  return (
    <InfoModal open={showSuccesModal} onClose={() => setShowSuccesModal(false)}>
      <Box margin={2} textAlign="center">
        <Box my={2}>
          <SuccessIcon />
        </Box>
        <Typography fontSize="1.4rem" variant="body1">
          {polyglot.t(
            'stoerk_id.detail_page.request.modal_request_sucess_title'
          )}
        </Typography>
        <Typography variant="body2" mt={2}>
          {polyglot.t(
            'stoerk_id.detail_page.request.modal_request_sucess_body'
          )}
        </Typography>
        <Typography variant="body2" mt={1}>
          {polyglot.t(
            'stoerk_id.detail_page.request.modal_request_sucess_note'
          )}
        </Typography>
      </Box>
    </InfoModal>
  );
}
