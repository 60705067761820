import { FeatureToggleState } from '../../featureToggle';
import { Group } from '../../redux/groups/api/group.model';
import GroupModel from '../../redux/groups/api/GroupModel';
import { RightUserAPIResponse } from '../../redux/rights/api/right.model';

const keyMasterScope = '*';
type DecodedRights = Record<
  RightUserAPIResponse['scope'],
  RightUserAPIResponse['rights']
>;

/**
 * Constanst Rights
 */
const AUTHORIZED_USER = 'authorized-user';
const DELETE_GROUP = 'deleteGroup';
const REMOVE_GROUP_DEVICE = 'removeGroupDevice';
const REMOVE_GROUP_USER = 'removeGroupUser';
const UPDATE_GROUP = 'updateGroup';
const UPDATE_DEVICE = 'updateDevice';
const ASSIGN_GROUP_DEVICE = 'assignGroupDevice';
const ASSIGN_GROUP_USER = 'assignGroupUser';
const READ_USER_RIGHTS = 'readUserRights';
const READ_ROLES = 'readRoles';
const WITH_DRAW_USER_RIGHT = 'withDrawUserRight';
const UPDATE_INCIDENT_CONFIG = 'updateIncidentConfig';
const READ_INCIDENT_CONFIG = 'readIncidentConfig';
const READ_INCIDENT = 'readIncident';
const UPDATE_INCIDENT = 'updateIncident';
const READ_SUPPORT_INFO_RIGHT = 'readSupportInfo';
const UPDATE_MESSAGE = 'updateMessage';
const DELETE_MESSAGE = 'deleteMessage';
const READ_MESSAGE = 'readMessage';
const PAIR_CAMERA = 'pairCamera';
const TRANSMIT_RECIPE = 'transmitRecipe';
const VIEW_RECIPE_TRANSMISSION = 'viewRecipeTransmission';
const READ_RECIPE = 'readRecipe';
const UPDATE_RECIPE = 'updateRecipe';
const CREATE_VOUCHER = 'createVoucher';
const READ_HISTORY = 'readHistory';
const RIGHT_DEPLOY_PARAMETERSET = 'deployParameterset';
// const STOERK_SALES_READ_QUOTAS = 'readStoerkQuotas';
const WORKSPACE_SHARE_DEVICE = 'shareWorkspaceDevice';
const WORKSPACE_VIEW = 'viewWorkspace';
const WORKSPACE_UPDATE = 'updateWorkspace';
const WORKSPACE_STRIPE_ACCESS = 'accessStripe';
const WORKSPACE_QUOTAS_UPDATE = 'updateStoerkQuotas';
const WORKSPACE_REQUEST_VIEW = 'viewWorkspaceRequest';
const WORKSPACE_REQUEST_UPDATE = 'updateWorkspaceRequest';
const WORKSPACE_USER_VIEW = 'viewWorkspaceUsers';
const WORKSPACE_USER_ADD = 'addWorkspaceUser';
const WORKSPACE_USER_DELETE = 'removeWorkspaceUser';
const WORKSPACE_DEVICE_VIEW = 'viewWorkspaceDevices';
const WORKSPACE_DEVICE_REMOVE = 'removeWorkspaceDevice';
const WORKSPACE_ALARM_POLICIES_UPDATE = 'updateAlarmPolicies';
const DELETE_WORKSPACE = 'addWorkspaceUser'; //FIXME: replace with a new right to delete

export default class RightsUserUtil {
  rightsUser: Record<
    RightUserAPIResponse['scope'],
    RightUserAPIResponse['rights']
  >;
  error: string;
  groups: Group[];

  constructor(
    roleList: RightUserAPIResponse[],
    groups: Group[],
    private featureToggle: FeatureToggleState = {}
  ) {
    this.rightsUser = RightsUserUtil.getDecodedRights(roleList);
    this.groups = groups;
    this.error = '';
  }

  static getDecodedRights(roleList: RightUserAPIResponse[]): DecodedRights {
    const rightsUser: DecodedRights = {};
    roleList?.forEach((role) => {
      if (!rightsUser[role.scope]) {
        rightsUser[role.scope] = [];
      }
      role.rights.forEach((right) => {
        if (rightsUser[role.scope].indexOf(right) < 0) {
          rightsUser[role.scope].push(right);
        }
      });
    });
    /* each user that is connected has per default the right authorized */
    if (!rightsUser['*']) {
      rightsUser['*'] = [];
    }
    rightsUser['*'].push('authorized-user');
    return rightsUser;
  }

  /**
   * Find parents group
   * This takes a node and loops over the lookup hash to get all of the parents
   */
  private findParentsGroup(groupIdBase64: string) {
    const pathGroup = GroupModel.getPathToGroup(
      { id: groupIdBase64 },
      this.groups
    );
    return pathGroup.map((path) => path.id);
  }

  /**
   * Get rights by group
   * this function get the rights from a group: join the rights from the group, the
   * scope * and all the parent groups
   * @param string groupIdBase64 (encode base 64)
   * @return array rightsGroups
   *
   */
  getRightsByGroup(groupIdBase64: string) {
    let rightsGroup: RightUserAPIResponse['rights'] = [];
    const groupId = atob(groupIdBase64);
    /* search if the group are in the scope group:####### */
    if (this.rightsUser[`group:${groupId}`]) {
      rightsGroup = this.rightsUser[`group:${groupId}`];
    }
    if (this.rightsUser[keyMasterScope]) {
      /* concat arrays and remove duplicates */
      rightsGroup = rightsGroup.concat(this.rightsUser[keyMasterScope]);
      rightsGroup = [...new Set(rightsGroup)];
    }

    /* search the rights in the parents groups */
    const parents = this.findParentsGroup(groupIdBase64);
    parents.forEach((parentIdBase64) => {
      const parentId = atob(parentIdBase64);
      if (this.rightsUser[`group:${parentId}`]) {
        /* concat arrays and remove duplicates */
        rightsGroup = rightsGroup.concat(this.rightsUser[`group:${parentId}`]);
        rightsGroup = [...new Set(rightsGroup)];
      }
    });
    return rightsGroup;
  }

  /**
   * Get rights by device
   * this function get the rights from a device: join the rights from the group and the
   * scope *
   * @param string deviceIdBase64
   * @return array rightsDevices
   */
  getRightsByDevice(deviceIdBase64: string) {
    let rightsDevice: RightUserAPIResponse['rights'] = [];
    const deviceId = atob(deviceIdBase64);
    /* search if the group are in the scope group:####### */
    if (this.rightsUser[`device:${deviceId}`]) {
      rightsDevice = this.rightsUser[`device:${deviceId}`];
    }
    if (this.rightsUser[keyMasterScope]) {
      rightsDevice = rightsDevice.concat(this.rightsUser[keyMasterScope]);
      rightsDevice = [...new Set(rightsDevice)];
    }
    return rightsDevice;
  }

  /**
   * Get rights by device
   * this function get the rights from a device: join the rights from the group and the
   * scope *
   * @param string deviceIdBase64
   * @return array rightsDevices
   */
  getRightsByWorkspace(workspaceId: string) {
    let rightsWorkspace: RightUserAPIResponse['rights'] = [];
    /* search if the workspace are in the scope workspace:####### */
    if (this.rightsUser[`workspace:${workspaceId}`]) {
      rightsWorkspace = this.rightsUser[`workspace:${workspaceId}`];
    }
    if (this.rightsUser[keyMasterScope]) {
      rightsWorkspace = rightsWorkspace.concat(this.rightsUser[keyMasterScope]);
      rightsWorkspace = [...new Set(rightsWorkspace)];
    }
    return rightsWorkspace;
  }

  /**
   * Get rigjts master scope
   * @return array rightsGroup
   */
  getRightsMasterScope() {
    let rightsGroup: RightUserAPIResponse['rights'] = [];
    if (this.rightsUser[keyMasterScope]) {
      rightsGroup = this.rightsUser[keyMasterScope];
    }
    return rightsGroup;
  }

  /**
   * Has right for gruop
   * this function checks if a group has a desired right
   * @param string groupIdBase64
   * @param string desiredRight
   * @return boolean
   *
   */
  hasRightForGroup(groupIdBase64: string, desiredRight: string) {
    const rightsGroup = this.getRightsByGroup(groupIdBase64);
    const found = rightsGroup.find((right) => right === desiredRight);
    return !(found === undefined);
  }

  /**
   * Has right for device
   * this function checks if a device has a desired right
   * @param string deviceIdBase64
   * @param string desiredRight
   * @return boolean
   */
  hasRightForDevice(deviceIdBase64: string, desiredRight: string) {
    const rightsDevice = this.getRightsByDevice(deviceIdBase64);

    const found = rightsDevice.find((right) => right === desiredRight);
    return !(found === undefined);
  }

  /**
   * Has right for worksapce
   * this function checks if the user has a desired right for a workspace
   * @param string worksapceId
   * @param string desiredRight
   * @return boolean
   */
  hasRightForWorkspace(workspaceId: string, desiredRight: string) {
    const rightsWorkspace = this.getRightsByWorkspace(workspaceId);
    return !!rightsWorkspace.find((right) => right === desiredRight);
  }

  /**
   * Has right
   * This function checks that the user has the right in any role
   * @param string deviceIdBase64
   * @return boolean
   */
  hasRight(desiredRight: string) {
    let found = false;
    Object.values(this.rightsUser).every((rights) => {
      if (rights.find((r) => r === desiredRight)) {
        found = true;
        return false;
      }
      return true;
    });

    return found;
  }

  /**
   * Has rights read messages
   * This function calls the function hasRight to check if the right READ_MESSAGE
   * exists in at least one of the roles
   * @return bool
   */
  hasRightReadMessages() {
    return this.hasRight(READ_MESSAGE);
  }

  /**
   * Has rights read incidents
   * This function calls the function hasRight to check if the right READ_INCIDENT
   * exists in at least one of the roles
   * @return bool
   */
  hasRightReadIncidents() {
    return !!this.featureToggle.alarmManager && this.hasRight(READ_INCIDENT);
  }

  /**
   * Has right master scope
   * this function checks if a desired right is found in the master scope rights
   * @param string desiredRight
   * @return boolean
   */
  hasRightMasterScope(desiredRight: string) {
    const rightsMasterScope = this.getRightsMasterScope();
    let found;
    if (rightsMasterScope.length > 0) {
      found = rightsMasterScope.find((right) => right === desiredRight);
    }
    return !(found === undefined);
  }

  /**
   * Is authorize user
   */
  isAuthorizedUser() {
    return this.hasRightMasterScope(AUTHORIZED_USER);
  }

  /**
   * Has rights to associate device
   * this function checks if the user is authorized in the master scope
   */
  hasRightsToAssociateDevice() {
    return this.hasRightMasterScope(AUTHORIZED_USER);
  }

  /**
   * Has right to assign group device
   * @param string groupIdBase64
   * @return boolean
   */
  hasRightsToAssignGroupDevice(groupIdBase64 = '') {
    return this.hasRightForGroup(groupIdBase64, ASSIGN_GROUP_DEVICE);
  }

  /**
   * Has right to delete a group
   * @param string groupIdBase64
   * @return boolean
   */
  hasRightsToDeleteGroup(groupIdBase64: string) {
    return this.hasRightForGroup(groupIdBase64, DELETE_GROUP);
  }

  /**
   * Has right to remove group device
   * @param string groupIdBase64
   * @return boolean
   */
  hasRightsToRemoveGroupDevice(groupIdBase64 = '') {
    return this.hasRightForGroup(groupIdBase64, REMOVE_GROUP_DEVICE);
  }

  /**
   * Has right to remove group user
   * @param string groupIdBase64
   * @return boolean
   */
  hasRightsToRemoveGroupUser(groupIdBase64: string) {
    return this.hasRightForGroup(groupIdBase64, REMOVE_GROUP_USER);
  }

  /**
   * Has right to update group
   * @param string groupIdBase64
   * @return boolean
   */
  hasRightsToUpdateGroup(groupIdBase64: string) {
    return this.hasRightForGroup(groupIdBase64, UPDATE_GROUP);
  }

  /**
   * Has right to assign group user
   * @param string groupIdBase64
   * @return boolean
   */
  hasRightsToAssignGroupUser(groupIdBase64: string) {
    return this.hasRightForGroup(groupIdBase64, ASSIGN_GROUP_USER);
  }

  /**
   * Has rights to read user rights
   * @param string groupIdBase64
   * @return boolean
   */
  hasRightsToReadUserRights(groupIdBase64: string) {
    return this.hasRightForGroup(groupIdBase64, READ_USER_RIGHTS);
  }

  /**
   * Has rights to read roles
   * @param string groupIdBase64
   * @return boolean
   */
  hasRightsToReadRoles(groupIdBase64: string) {
    return this.hasRightForGroup(groupIdBase64, READ_ROLES);
  }

  /**
   * Has rights to withdraw user right
   * @param string groupIdBase64
   * @return boolean
   */
  hasRightsToWithDrawUserRight(groupIdBase64: string) {
    return this.hasRightForGroup(groupIdBase64, WITH_DRAW_USER_RIGHT);
  }

  /**
   * Has rights to update incident config
   * @param string groupIdBase64
   * @return boolean
   */
  hasRightsToUpdateIncidentConfig(groupIdBase64: string) {
    return (
      !!this.featureToggle.alarmManager &&
      this.hasRightForGroup(groupIdBase64, UPDATE_INCIDENT_CONFIG)
    );
  }

  /**
   * Has rights to read incident config
   * @param string groupIdBase64
   * @return boolean
   */
  hasRightsToReadIncidentConfig(groupIdBase64: string) {
    return (
      !!this.featureToggle.alarmManager &&
      this.hasRightForGroup(groupIdBase64, READ_INCIDENT_CONFIG)
    );
  }

  /**
   * Check rights device in associated groups
   * This function check if
   * @param string deviceIdBase64
   * @param string desiredRight
   * @return bool
   */
  checkRightDeviceInAssociatedGroups(
    deviceIdBase64: string,
    desiredRight: string
  ) {
    let result = false;
    const groupsIds = GroupModel.getGroupsIdByDeviceId(
      deviceIdBase64,
      this.groups
    );
    if (groupsIds.length > 0) {
      result = groupsIds.some((id) => this.hasRightForGroup(id, desiredRight));
    }
    return result;
  }

  /**
   * Has rights for group or for device
   *
   * @param string deviceIdBase64
   * @param string groupIdBase64
   * @param string desiredRight
   * @return boolean
   */
  hasRightsForGroupOrForDevice(
    deviceIdBase64: string | null = null,
    groupIdBase64: string | null = null,
    desiredRight: string
  ) {
    let result = false;
    const hasRightMasterScope = this.hasRightMasterScope(desiredRight);
    if (hasRightMasterScope) {
      result = true;
    }
    if (groupIdBase64 && !deviceIdBase64) {
      result = this.hasRightForGroup(groupIdBase64, desiredRight);
    }
    /* if the group is null and the device has not rights, we need to check if
      the device is associated with a group, which has these rights */
    if (deviceIdBase64 && !groupIdBase64) {
      const hasRightForDevice = this.hasRightForDevice(
        deviceIdBase64,
        desiredRight
      );
      if (hasRightForDevice) {
        result = true;
      } else {
        const right = this.checkRightDeviceInAssociatedGroups(
          deviceIdBase64,
          desiredRight
        );
        result = right;
      }
    }
    if (deviceIdBase64 && groupIdBase64) {
      const hasRightsByGroup = this.hasRightForGroup(
        groupIdBase64,
        desiredRight
      );
      const hasRightsByDevice = this.hasRightForDevice(
        deviceIdBase64,
        desiredRight
      );
      result = hasRightsByGroup || hasRightsByDevice;
    }
    return result;
  }

  /**
   * Has rights to read incident
   * @param string deviceIdBase64
   * @param string groupIdBase64
   * @return boolean
   */
  hasRightsToReadIncident(
    deviceIdBase64: string | null = null,
    groupIdBase64: string | null = null
  ) {
    return (
      !!this.featureToggle.alarmManager &&
      this.hasRightsForGroupOrForDevice(
        deviceIdBase64,
        groupIdBase64,
        READ_INCIDENT
      )
    );
  }

  /**
   * Has rights to update incident
   * @param string deviceIdBase64
   * @param string groupIdBase64
   * @return boolean
   */
  hasRightsToUpdateIncident(
    deviceIdBase64: string | null = null,
    groupIdBase64: string | null = null
  ) {
    return (
      !!this.featureToggle.alarmManager &&
      this.hasRightsForGroupOrForDevice(
        deviceIdBase64,
        groupIdBase64,
        UPDATE_INCIDENT
      )
    );
  }

  /**
   * Has right to read the maintenance notification messages by a group
   * @param string groupIdBase64
   * @return boolean
   */
  hasRightsToReadMessagesGroup(groupIdBase64: string) {
    if (!this.featureToggle.maintenanceMessages) return false;
    return this.hasRightForGroup(groupIdBase64, READ_MESSAGE);
  }

  /**
   * Has right to read the maintenance notification messages by a device
   * @param string groupIdBase64
   * @return boolean
   */
  hasRightsToReadMessagesDevice(deviceIdBase64: string) {
    if (!this.featureToggle.maintenanceMessages) return false;
    return this.hasRightForDevice(deviceIdBase64, READ_MESSAGE);
  }

  /**
   * Has right to update a maintenance notification message by a group
   * @param string groupIdBase64
   * @return boolean
   */
  hasRightsToUpdateMessageGroup(groupIdBase64 = '') {
    return this.hasRightForGroup(groupIdBase64, UPDATE_MESSAGE);
  }

  /**
   * Has right to update a maintenance notification message by device
   * @param string deviceIdBase64
   * @return boolean
   */
  hasRightsToUpdateMessageDevice(deviceIdBase64: string) {
    return this.hasRightForDevice(deviceIdBase64, UPDATE_MESSAGE);
  }

  /**
   * Has right to delete a maintenance notification message by a group
   * @param string groupIdBase64
   * @return boolean
   */
  hasRightsToDeleteMessageGroup(groupIdBase64 = '') {
    return this.hasRightForGroup(groupIdBase64, DELETE_MESSAGE);
  }

  /**
   * Has right to delete a maintenance notification message by device
   * @param string deviceIdBase64
   * @return boolean
   */
  hasRightsToDeleteMessageDevice(deviceIdBase64: string) {
    return this.hasRightForDevice(deviceIdBase64, UPDATE_MESSAGE);
  }

  isSupportUser() {
    return this.hasRightMasterScope(READ_SUPPORT_INFO_RIGHT);
  }

  hasRightsToReadHistory() {
    return this.hasRightMasterScope(READ_HISTORY);
  }

  /**
   * Has right to pair camera
   * @param string groupIdBase64
   * @return bool right
   */
  hasRightsToPairCamera(groupIdBase64: string) {
    return this.hasRightForGroup(groupIdBase64, PAIR_CAMERA);
  }

  hasRightsToUpdateDevice(
    deviceIdBase64: null | string = null,
    groupIdBase64: null | string = null
  ) {
    return this.hasRightsForGroupOrForDevice(
      deviceIdBase64,
      groupIdBase64,
      UPDATE_DEVICE
    );
  }

  /**
   * Has rights to transmit recipe
   * @param string deviceIdBase64
   * @param string groupIdBase64
   * @return bool right
   */
  hasRightsToTransmitRecipe(
    deviceIdBase64: null | string = null,
    groupIdBase64: null | string = null
  ) {
    return this.hasRightsForGroupOrForDevice(
      deviceIdBase64,
      groupIdBase64,
      TRANSMIT_RECIPE
    );
  }

  /**
   * Has rights to view recipe transmission
   * @param string deviceIdBase64
   * @param string groupIdBase64
   * @return bool right
   */
  hasRightsToViewRecipeTransmission(
    deviceIdBase64: null | string,
    groupIdBase64: null | string
  ) {
    return this.hasRightsForGroupOrForDevice(
      deviceIdBase64,
      groupIdBase64,
      VIEW_RECIPE_TRANSMISSION
    );
  }

  /**
   * Has righs to read recipe
   * @param string deviceIdBase64
   * @param string groupIdBase64
   * @return bool right
   */
  hasRightsToReadRecipe(
    deviceIdBase64: null | string = null,
    groupIdBase64: null | string = null
  ) {
    return this.hasRightsForGroupOrForDevice(
      deviceIdBase64,
      groupIdBase64,
      READ_RECIPE
    );
  }

  /**
   * Has righst to update recipe
   * @param string deviceIdBase64
   * @param string groupIdBase64
   * @return bool right
   */
  hasRightsToUpdateRecipe(
    deviceIdBase64: null | string,
    groupIdBase64: null | string
  ) {
    return this.hasRightsForGroupOrForDevice(
      deviceIdBase64,
      groupIdBase64,
      UPDATE_RECIPE
    );
  }

  hasRightsToUpdateParameter(
    deviceIdBase64: null | string = null,
    groupIdBase64: null | string = null
  ) {
    return this.hasRightsForGroupOrForDevice(
      deviceIdBase64,
      groupIdBase64,
      RIGHT_DEPLOY_PARAMETERSET
    );
  }

  /**
   * Has rights to create voucher
   * @return bool right
   */
  hasRightsToCreateVoucher() {
    const hasRightMasterScope = this.hasRightMasterScope(CREATE_VOUCHER);
    return hasRightMasterScope;
  }

  /**
   * Has rights to read Quota, means is a sales team person
   */
  hasRightsToReadQuota() {
    // FIXME: This right has some issue because should be allowed for Stoerk Sales team and Workspace Admin
    // return this.hasRightMasterScope(STOERK_SALES_READ_QUOTAS);
    return true;
  }

  /** WORKSPACES */
  canAskToDeleteWorkspace(workspaceId: string) {
    return this.hasRightForWorkspace(workspaceId, DELETE_WORKSPACE);
  }
  canRemoveUserFromWorkspace(workspaceId: string) {
    return this.hasRightForWorkspace(workspaceId, WORKSPACE_USER_DELETE);
  }
  canAddUserToWorkspace(workspaceId: string) {
    return this.hasRightForWorkspace(workspaceId, WORKSPACE_USER_ADD);
  }
  canShareDeviceInWorkspace(workspaceId: string) {
    return this.hasRightForWorkspace(workspaceId, WORKSPACE_SHARE_DEVICE);
  }
  canViewWorkspace(workspaceId: string) {
    return this.hasRightForWorkspace(workspaceId, WORKSPACE_VIEW);
  }
  canUpdateWorkspace(workspaceId: string) {
    return this.hasRightForWorkspace(workspaceId, WORKSPACE_UPDATE);
  }
  canAccessStripeWorkspace(workspaceId: string) {
    return this.hasRightForWorkspace(workspaceId, WORKSPACE_STRIPE_ACCESS);
  }
  canUpdateQuotasWorkspace(workspaceId: string) {
    return this.hasRightForWorkspace(workspaceId, WORKSPACE_QUOTAS_UPDATE);
  }
  canViewWorkspaceRequest(workspaceId: string) {
    return this.hasRightForWorkspace(workspaceId, WORKSPACE_REQUEST_VIEW);
  }
  canUpdateWorkspaceRequest(workspaceId: string) {
    return this.hasRightForWorkspace(workspaceId, WORKSPACE_REQUEST_UPDATE);
  }
  canViewWorkspaceUsers(workspaceId: string) {
    return this.hasRightForWorkspace(workspaceId, WORKSPACE_USER_VIEW);
  }
  canViewWorkspaceDevices(workspaceId: string) {
    return this.hasRightForWorkspace(workspaceId, WORKSPACE_DEVICE_VIEW);
  }
  canRemoveWorkspaceDevice(workspaceId: string) {
    return this.hasRightForWorkspace(workspaceId, WORKSPACE_DEVICE_REMOVE);
  }
  canUpdateAlarmPolicies(workspaceId: string) {
    return this.hasRightForWorkspace(
      workspaceId,
      WORKSPACE_ALARM_POLICIES_UPDATE
    );
  }
}
