import React from 'react';
import StoerkIdRequestLayout from './StoerkIdRequestLayout';
import RequestProcessSteps from './RequestProcessSteps';
import { Box, Button, Container, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { getPolyglot } from '../../../i18n';
import useBack from '../../../util/route-dom/hooks/useBack';

export default function StoerkIdRequestOne() {
  const params = useParams<{
    stoerkPlan: string;
    companyName: string;
    companyInternalId: string;
  }>();
  const polyglot = getPolyglot();
  const navigate = useNavigate();
  const goHome = () => navigate('/');
  const onClickBack = useBack(
    `/stoerk-id/request/${params.companyName}/${params.companyInternalId}/select-plan`
  );
  return (
    <StoerkIdRequestLayout>
      <RequestProcessSteps activeStep={3} onClickBack={onClickBack} />
      <Box sx={{ mt: 2 }}>
        <Typography variant="h1">
          {polyglot.t('stoerk_id.request_form.selected_one_plan_title')}
        </Typography>
        <Typography mt={0.5}>
          {polyglot.t('stoerk_id.request_form.selected_one_plan_note')}
        </Typography>
        <form onSubmit={goHome}>
          <Container
            maxWidth="md"
            disableGutters
            sx={{
              mt: 2,
            }}
          >
            <Box></Box>
            <Box
              sx={{
                textAlign: 'center',
                mt: 3,
              }}
            >
              <Button
                variant="contained"
                size="large"
                color="primary"
                type="submit"
                sx={{
                  paddingX: 4,
                }}
              >
                {polyglot.t('nav.home')}
              </Button>
            </Box>
          </Container>
        </form>
        <Box sx={{ flexGrow: 1 }}></Box>
      </Box>
    </StoerkIdRequestLayout>
  );
}
